import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
} from "reactstrap";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { TimeTableAPI } from "../../../../api/TimeTable/timeTableAPI";
import useSessionRedirect from "../../Comman/SessionChange";
import {
  ClearButton,
  SaveButton,
  ViewButton,
} from "../../../CommonComponent/ReusableButton";
import { Heading } from "../../../CommonComponent/heading";
import { DayList, TypeList } from "../../../../data/optionData";
import { SelectField } from "../../../CommonComponent/SelectField";
import { CiRollingSuitcase } from "react-icons/ci";

const PeriodConfiguration = () => {
  const token = localStorage.getItem("token");
  const session_id = useSessionRedirect();
  const school_user = JSON.parse(localStorage.getItem("school_user"));
  let school_id = school_user?.user_detail?.fk_school_id;
  let { school_emp_id } = school_user?.user_detail;

  const dispatch = useDispatch();
  const { classsectionList } = useSelector((state) => state.attendance);
  const [selectClass, setSelectClass] = useState(null);
  const [isCopyClass, setIsCopyClass] = useState(null);
  const [selectDay, setSelectDay] = useState(null);
  const [isButtonDisables, setIsButtonDisables] = useState(true);
  const [isButtonSave, setIsButtonSave] = useState(true);
  const [isTimetableData, setIsTimetableData] = useState([]);
  const [periodMasterId, setPeriodMasterId] = useState(null);
  const [edit, setEdit] = useState(false);
  const [modal, setModal] = useState(false);

  const [selectDays, setSelectDays] = useState(null);
  const [isClassSection, setIsClassSection] = useState(null);
  const [isCopyDay, setIsCopyDay] = useState(null);
  const [disableDays, setDisableDays] = useState(true);
  const [isWeekDays, setIsWeekDays] = useState(false);
  const [isClass, setIsClass] = useState(false);
  const [isCopyButtonEnable, setIsCopyButtonEnable] = useState(false);

  let classListOption = classsectionList?.payload?.map((c) => {
    return {
      label: `${c.class_name} ${c.section}`,
      value: c.id,
    };
  });

  let handleChangeClass = (select) => {
    setSelectClass(select);
  };

  let handleDayChange = (select) => {
    setSelectDay(select);
    setIsButtonSave(true);
    setSelectDays(null);
    setDisableDays(true);
  };

  const generateTimeOptions = () => {
    const options = [];
    for (let hour = 6; hour <= 23; hour++) {
      for (let minute = 0; minute < 60; minute += 5) {
        const period = hour < 12 ? "AM" : "PM";
        const formattedHour = (hour % 24 || 24).toString().padStart(2, "0");
        const formattedMinute = minute.toString().padStart(2, "0");
        options.push(`${formattedHour}:${formattedMinute}:00`);
      }
    }
    return options;
  };

  const timeListFrom = generateTimeOptions().map((time, index) => {
    return {
      label: time,
      value: time,
    };
  });
  const timeListTo = generateTimeOptions().map((time, index) => {
    return {
      label: time,
      value: time,
    };
  });

  const initialData = {
    id: "",
    fromTime: null,
    toTime: null,
    description: "",
    isBreak: false,
    type: null,
  };
  const [tableData, setTableData] = useState([initialData]);
  let handleClear = () => {
    setIsTimetableData([]);
    setPeriodMasterId(null);
    setSelectClass(null);
    setSelectDay(null);
    setIsButtonDisables(true);
    setIsButtonSave(true);
    setTableData([initialData]);
    setEdit(false);
    setDisableDays(true);
    setSelectDays(null);
    setIsCopyDay(null);
    setIsCopyClass(null);
    setIsWeekDays(false);
    setIsClass(false);
    setIsCopyButtonEnable(false)
  };
  useEffect(() => {
    if (isTimetableData?.length > 0) {
      let selectData = isTimetableData?.map((d) => {
        return {
          id: d.id,
          fromTime: d.from_time,
          toTime: d.to_time,
          description: d.description,
          isBreak: d.is_break,
          type: d.period_type,
        };
      });
      setTableData(selectData);
    } else {
      setTableData([initialData]);
    }
  }, [isTimetableData]);
  const handleFromTimeChange = (e, rowIndex) => {
    const updatedData = [...tableData];
    updatedData[rowIndex].fromTime = e.value;
    setTableData(updatedData);
  };

  const handleToTimeChange = (e, rowIndex) => {
    const updatedData = [...tableData];
    updatedData[rowIndex].toTime = e.value;
    setTableData(updatedData);
  };

  const handleDescriptionChange = (e, rowIndex) => {
    const updatedData = [...tableData];
    updatedData[rowIndex].description = e.target.value;
    setTableData(updatedData);
  };

  const handleIsBreakChange = (e, rowIndex) => {
    const updatedData = [...tableData];
    updatedData[rowIndex].isBreak = e.target.checked;
    setTableData(updatedData);
  };

  const handleSelectTypeChange = (e, rowIndex) => {
    const updatedData = [...tableData];
    updatedData[rowIndex].type = e.value;
    setTableData(updatedData);
  };

  const addRow = () => {
    const newRow = {
      fromTime: null,
      toTime: null,
      description: "",
      isBreak: false,
      type: null,
    };
    setTableData([...tableData, newRow]);
  };

  const deleteRow = (indexToDelete) => {
    if (indexToDelete === tableData?.length - 1) {
      Swal.fire({
        icon: "warning",
        title: "ALERT",
        text: "Can not Delete !",
        allowOutsideClick: false,
      });
      return;
    }
    const updatedTableData = tableData.filter(
      (_, index) => index !== indexToDelete
    );
    setTableData(updatedTableData);
  };
  useEffect(() => {
    if (selectClass && selectDay?.value) {
      setIsButtonDisables(false);
    }
  }, [selectClass, selectDay?.value]);

  const fetchData = async () => {
    try {
      let data = {
        academic_id: session_id,
        school_id: school_id,
        section_id: selectClass.value,
        day: selectDay?.value,
      };
      let response = await TimeTableAPI.getPeriodiConfigurationList(data);

      if (response.status === 200) {
        const period = response.data?.data;
        setIsCopyButtonEnable(period?.length > 0 ? true : false);
        if (period && period.length > 0) {
          period.forEach((p) => {
            setIsTimetableData(p?.period_details);
            setPeriodMasterId(p?.period_master_id);
          });
        } else {
          setIsTimetableData([]);
          setPeriodMasterId(null);
        }
        if (period[0]?.period_master_id) {
          setEdit(true);
          setDisableDays(false);
        } else {
          setEdit(false);
        }
        setIsButtonSave(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleFetchData = () => {
    fetchData();
  };

  const sendDataToServer = async () => {
    let days = selectDays ? selectDays?.map((d) => d.value) : [];
    const peroid_details_lst = tableData?.map((p) => {
      return {
        id: p.id || "",
        from_time: p.fromTime,
        to_time: p.toTime,
        description: p.description,
        is_break: p.isBreak,
        period_type: p.isBreak === true ? null : p.type,
      };
    });
    let filter_period = peroid_details_lst?.filter(
      (item) => item.from_time !== null || item.to_time !== null
    );
    let data = {
      period_master_id: periodMasterId || "",
      academic_id: session_id,
      school_id: school_id,
      section_id: selectClass.value,
      insert_by: school_emp_id,
      day: selectDay?.value,
      selected_days: JSON.stringify(days),
      peroid_details_lst: JSON.stringify(filter_period),
    };

    const hasEmptyFromTo = peroid_details_lst?.some(
      (p) => !p.from_time || !p.to_time
    );

    if (peroid_details_lst.length === 1 && hasEmptyFromTo) {
      Swal.fire("Error", "Add Atleast One Record ! ", "error");
      return;
    }
    Swal.fire({
      title: "CONFIRMATION",
      text: edit
        ? "Do you want to Update Record ?"
        : "Do you want to Save Record ?",
      icon: "question",
      showDenyButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#f46a6a",
      confirmButtonText: "Yes",
      denyButtonText: `No`,
      allowOutsideClick: false,
    }).then(async (result) => {
      if (result.isConfirmed) {
        let response = await TimeTableAPI.addPeriodConfiguration(data);
        if (response.data.status === 200) {
          Swal.fire({
            text: response.data.msg,
            icon: "success",
            imageAlt: "warning image",
            allowOutsideClick: false,
          }).then((result) => {
            if (result.isConfirmed) {
              handleClear();
            }
          });
        }
        if (response.data.status === 403) {
          Swal.fire({
            text: response.data.msg,
            icon: "error",
            imageAlt: "error image",
            allowOutsideClick: false,
          });
        }
      } else if (result.isDenied) {
        // window.location.href = "/school/bonafide";
      }
    });
  };

  const CopyTimeTable = async () => {
    let days = isCopyDay ? isCopyDay?.map((d) => d.value) : [];
    let Classes = isCopyClass ? isCopyClass?.map((d) => d.value) : [];
    const peroid_details_lst = tableData?.map((p) => {
      return {
        id: p.id || "",
        from_time: p.fromTime,
        to_time: p.toTime,
        description: p.description,
        is_break: p.isBreak,
        period_type: p.isBreak === true ? null : p.type,
      };
    });
    let filter_period = peroid_details_lst?.filter(
      (item) => item.from_time !== null || item.to_time !== null
    );
    let data = {
      academic_id: session_id,
      school_id: school_id,
      section_lst: JSON.stringify(Classes),
      insert_by: school_emp_id,
      days_lst: JSON.stringify(days),
      peroid_details_lst: JSON.stringify(filter_period),
    };

    const hasEmptyFromTo = peroid_details_lst?.some(
      (p) => !p.from_time || !p.to_time
    );

    if (peroid_details_lst.length === 1 && hasEmptyFromTo) {
      Swal.fire("Error", "Add Atleast One Record ! ", "error");
      return;
    }
    Swal.fire({
      title: "CONFIRMATION",
      text: edit
        ? "Do you want to Copy Time Table ?"
        : "Do you want to Save Record ?",
      icon: "question",
      showDenyButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#f46a6a",
      confirmButtonText: "Yes",
      denyButtonText: `No`,
      allowOutsideClick: false,
    }).then(async (result) => {
      if (result.isConfirmed) {
        let response = await TimeTableAPI.copyPeriodConfiguration(data);
        if (response.data.status === 200) {
          Swal.fire({
            text: response.data.msg,
            icon: "success",
            imageAlt: "warning image",
            allowOutsideClick: false,
          }).then((result) => {
            if (result.isConfirmed) {
              handleClear();
              toggle();
            }
          });
        }
        if (response.data.status === 403) {
          Swal.fire({
            text: response.data.msg,
            icon: "error",
            imageAlt: "error image",
            allowOutsideClick: false,
          });
        }
      } else if (result.isDenied) {
        // window.location.href = "/school/bonafide";
      }
    });
  };
  const OptionWithCheckbox = ({ innerProps, label, isSelected }) => (
    <div {...innerProps}>
      <input type="checkbox" checked={isSelected} readOnly />
      {label}
    </div>
  );

  const handleClassSelect = (e) => {
    setIsClass(e.target.checked);
    if (!e.target.checked) {
      setIsCopyClass(null); // Clear selection if checkbox is unchecked
    }
  };

  const handleWeekDaySelect = (e) => {
    setIsWeekDays(e.target.checked);
    if (!e.target.checked) {
      setIsCopyDay(null); // Clear selection if checkbox is unchecked
    }
  };

  const toggle = () => setModal(!modal);
  const HandelModalClear = () => {
    setModal(!modal);
    setIsWeekDays(false);
    setIsClass(false);
    setIsCopyDay(null);
    setIsCopyClass(null);
  };

  return (
    <>
      <Container fluid={true}>
        <Heading pageTitle={"Period Configuration"}>
          <ViewButton
            className="mx-1"
            onClick={handleFetchData}
            disabled={isButtonDisables}
          />

          <SaveButton
            className="mx-1"
            onClick={() => sendDataToServer()}
            disabled={isButtonSave}
            isEdit={edit}
          />
          <ClearButton className="mx-1" onClick={() => handleClear()} />
        </Heading>
        <Card className="mt-4">
          <CardBody>
            <Row className="">
              <Col lg={3} md={6} className="mb-1">
                <Label
                  className="form-check-label grow-1"
                  htmlFor="inlineFormCheck"
                >
                  Select Class
                </Label>
                <Select
                  name="class_id"
                  placeholder="Select Class"
                  options={classListOption}
                  value={
                    selectClass
                      ? classListOption?.find((c) => c.value === selectClass)
                      : null
                  }
                  onChange={handleChangeClass}
                  isSearchable={true}
                />
              </Col>
              <Col lg={3} md={6} className="mb-1">
                <Label
                  className="form-check-label grow-1"
                  htmlFor="inlineFormCheck"
                >
                  Select Day
                </Label>

                <Select
                  name="class_id"
                  placeholder="Select Day"
                  options={DayList}
                  value={selectDay}
                  onChange={handleDayChange}
                  isSearchable={true}
                />
              </Col>
              {isCopyButtonEnable && (
                <Col lg={6} className="align-self-end mb-1">
                  <button
                    className="btn btn-success "
                    type="button"
                    onClick={() => setModal(!modal)}
                  >
                    Copy Time Table
                  </button>
                </Col>
              )}
            </Row>
          </CardBody>
          <CardBody>
            <div className="table-responsive" style={{ height: "53vh" }}>
              <Table
                className="table table-striped text-nowrap"
                id="table-to-xls"
              >
                <thead>
                  <tr>
                    <th>From Time</th>
                    <th>To Time</th>
                    <th>Description</th>
                    <th className="text-center">Break</th>
                    <th className="text-center">Type</th>
                    <th className="text-center">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {tableData?.map((rowData, index) => (
                    <tr key={index}>
                      <td>
                        <SelectField
                          name={`fromTime-${index}`}
                          placeholder="From Time"
                          options={timeListFrom}
                          value={rowData.fromTime}
                          onChange={(e) => handleFromTimeChange(e, index)}
                        />
                      </td>
                      <td>
                        <SelectField
                          name={`toTime-${index}`}
                          placeholder="To Time"
                          options={timeListTo}
                          value={rowData.toTime}
                          onChange={(e) => handleToTimeChange(e, index)}
                        />
                      </td>
                      <td className="align-middle">
                        <input
                          name={`Description-${index}`}
                          type="text"
                          placeholder="Description"
                          className="form-control  border"
                          value={rowData.description}
                          onChange={(e) => handleDescriptionChange(e, index)}
                        />
                      </td>
                      <td className="text-center align-middle">
                        <input
                          name={`Break-${index}`}
                          type="checkbox"
                          className="text-center"
                          checked={rowData.isBreak}
                          onChange={(e) => handleIsBreakChange(e, index)}
                        />
                      </td>
                      <td className="">
                        <SelectField
                          name={`Type-${index}`}
                          placeholder="Select Type"
                          options={TypeList}
                          value={rowData.type}
                          onChange={(e) => handleSelectTypeChange(e, index)}
                          isDisabled={rowData.isBreak}
                        />
                      </td>
                      <td className="text-center">
                        {index === tableData?.length - 1 && (
                          <Button
                            className="btn btn-primary btn-sm mx-1"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Add Row"
                            onClick={addRow}
                            type="button"
                          >
                            <i className="fa fa-plus"></i>
                          </Button>
                        )}

                        <button
                          type="button"
                          className="btn btn-outline-danger btn-sm mx-1"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Delete"
                          onClick={() => deleteRow(index)}
                        >
                          <i className="fa fa-trash"></i>
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </CardBody>{" "}
        </Card>
      </Container>

      <div>
        <Modal isOpen={modal} toggle={toggle}>
          <ModalHeader>Copy Time Table</ModalHeader>
          <ModalBody>
            <div className="form-group row">
              <label className="col-lg-4 col-form-label">Class</label>

              <div className="col-lg-8">
                <input
                  name="class_id"
                  placeholder="Select Class"
                  className="form-control"
                  type="text"
                  disabled
                  value={selectClass?.label}
                  isSearchable={true}
                />
              </div>
            </div>
            <div className="form-group row">
              <label className="col-lg-4 col-form-label">Selected day</label>

              <div className="col-lg-8">
                <input
                  className="form-control"
                  isDisabled={true}
                  placeholder="Select Day"
                  type="text"
                  disabled
                  value={selectDay?.label}
                />
              </div>
            </div>
            <div className="form-group row">
              <div className="col-lg-4">
                <p>Copy to other week days</p>
              </div>
              <div className="col-lg-8">
                <input
                  type="checkbox"
                  className="text-center"
                  checked={isWeekDays}
                  onChange={handleWeekDaySelect}
                />
              </div>
            </div>
            <div className="form-group row">
              <div className="col-lg-4">Select days</div>
              <div className="col-lg-8">
                <Select
                  isMulti
                  placeholder="Select Days"
                  // options={DayList.filter((d) => d.value !== selectDay?.value)}
                  options={DayList}
                  value={isWeekDays ? isCopyDay : null}
                  onChange={(e) => setIsCopyDay(e)}
                  isSearchable={true}
                  isDisabled={!isWeekDays}
                />
              </div>
            </div>

            <div className="form-group row">
              <div className="col-lg-4">
                <p>Copy to other class sections</p>
              </div>
              <div className="col-lg-8">
                <input
                  type="checkbox"
                  className="text-center"
                  checked={isClass}
                  onChange={handleClassSelect}
                />
              </div>
            </div>

            <div className="form-group row">
              <div className="col-lg-4">Select class</div>
              <div className="col-lg-8">
                <Select
                  name="class_id"
                  placeholder="Select Class"
                  isMulti
                  // options={classListOption?.filter(
                  //   (d) => d.value !== selectClass?.value
                  // )}
                  options={classListOption}
                  value={isClass ? isCopyClass : null}
                  onChange={(e) => setIsCopyClass(e)}
                  isSearchable={true}
                  isDisabled={!isClass}
                />
              </div>
            </div>
          </ModalBody>

          <ModalFooter>
            <Button color="primary" onClick={CopyTimeTable}>
              Copy
            </Button>{" "}
            <Button color="secondary" onClick={HandelModalClear}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    </>
  );
};

export default PeriodConfiguration;
