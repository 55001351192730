import React, { useState } from "react";
import Swal from "sweetalert2";
import { ModalBody, ModalHeader, Table } from "reactstrap";
import { convertDate } from "../../CommonComponent/DateFormatUtil";
import { CustomModalBody, CustomModalHeader } from "../../CommonComponent/modelComponent";
const FeeReceiptList = (props) => {
  const {
    toggle,
    receiptData,
    searchResults,
    setSearchResults,
    noResultsMessage,
    setNoResultsMessage,
    handleStudentEdit,
  } = props;
  const [searchQuery, setSearchQuery] = useState("");
  const [idQuery, setIdQuery] = useState("");
  const [receiptNo, setReceiptNo] = useState("");

  const handleInputChangeSearch = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
  };
  const handleIdInputChange = (e) => {
    const query = e.target.value;
    setIdQuery(query);
  };
  const handleReceiptChange = (e) => {
    const query = e.target.value;
    setReceiptNo(query);
  };
  const handleSearch = () => {
    // if (
    //   searchQuery.trim() === "" &&
    //   idQuery.trim() === "" &&
    //   receiptNo.trim() === ""
    // ) {
    //   Swal.fire({
    //     text: "Please enter value",
    //     icon: "error",
    //     allowOutsideClick: false,
    //   });

    //   return;
    // }
  
    const filteredResults = receiptData?.filter(
      (item) =>
        item.student_name?.toLowerCase().includes(searchQuery?.toLowerCase()) &&
        item.student_code?.toString().includes(idQuery) &&
        item.receipt_no?.toString().includes(receiptNo)
    );
    setSearchResults(filteredResults);
    setSearchQuery("");
    setIdQuery("");
    setReceiptNo("");
    if (filteredResults?.length === 0) {
      setNoResultsMessage("No results found.");
    } else {
      setNoResultsMessage("");
    }
  };
 
  return (
    <>
      <CustomModalHeader toggle={toggle}>
        <div className="row">
          <div className="col-lg-3 col-md-3 col-sm-4 my-1">
            <input
              name="code"
              type="text"
              className="form-control"
              placeholder="Student code"
              value={idQuery}
              onChange={handleIdInputChange}
              autoComplete="off"
            />
          </div>
          <div className="col-lg-3 col-md-3 col-sm-4 my-1">
            <input
              name="code"
              type="number"
              className="form-control"
              placeholder="Receipt No"
              value={receiptNo}
              onChange={handleReceiptChange}
              autoComplete="off"
            />
          </div>
          <div className="col-lg-5 col-md-3 col-sm-4 my-1 ">
            <input
              type="text"
              className="form-control"
              name="name"
              placeholder="Student Name  "
              value={searchQuery}
              onChange={handleInputChangeSearch}
              autoComplete="off"
            />
          </div>
          <div className="col-lg-1 col-md-2 my-1 text-right">
            <button
              type="submit"
              className="btn btn-primary"
              onClick={handleSearch}
            >
              Search
            </button>
          </div>
        </div>
      </CustomModalHeader>
      <CustomModalBody noResultsMessage={noResultsMessage}>
        <thead>
          <tr className="text-center">
            <th>sr</th>
            <th>Receipt No</th>
            <th>Receipt Date</th>
            <th>Stud Code</th>
            <th>Roll no</th>
            <th>Student Name</th>
            <th>Class</th>
            <th>Concession</th>
          </tr>
        </thead>
        <tbody>
          {searchResults
            ?.filter((s) => s.is_cancel === false)
            ?.map((stu, index) => {
              return (
                <tr
                  key={index}
                  className="text-center"
                  onClick={() => handleStudentEdit(stu)}
                  style={{ cursor: "pointer"}}
                >
                  <td>{index + 1}</td>
                  <td>{stu.receipt_no}</td>
                  <td>{convertDate(stu.receipt_date)}</td>
                  <td>{stu.student_code}</td>
                  <td>{stu.roll_no}</td>
                  <td className="text-left">{stu.student_name}</td>
                  <td>{stu.class_sec}</td>
                  <td>{stu.concession}</td>
                </tr>
              );
            })}
        </tbody>
      </CustomModalBody>

      {/* <ModalFooter>
        <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter> */}
    </>
  );
};

export default FeeReceiptList;

{
  /* <div className="col-lg-4 col-md-4 d-flex justify-content-end">
            <button
              type="button"
              className="btn btn-outline-danger btn-sm"
              onClick={toggle}
            >
              <i className="fa fa-times"></i>
            </button>
          </div> */
}
