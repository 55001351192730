import React, { useState, useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import {
  getClass,
  getSection,
} from "../../../../actions/Admission/StudentReport";
import Swal from "sweetalert2";
import { feeDiscountAPI } from "../../../../api/Admission/feeDiscountAPI";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import useSessionRedirect from "../../Comman/SessionChange";
import useLoading from "../../../CommonComponent/useLoading";
import Loader from "../../../Loader/Loader";
import { ExportButton } from "../../../CommonComponent/ReusableButton";
import { Heading } from "../../../CommonComponent/heading";

const FeeOutstandingReport = () => {
  const token = localStorage.getItem("token");
  const school_user = JSON.parse(localStorage.getItem("school_user"));
  const { email, id, user_type, name, user_detail } = school_user;
  const { school_emp_id } = user_detail;
  let school_id = school_user?.user_detail?.fk_school_id;
  const session_id = useSessionRedirect();
  const { allClass } = useSelector((state) => state.getClass);
  const { allSection } = useSelector((state) => state.getSection);
  const dispatch = useDispatch();
  const [feeHeadsList, setFeeHeadsList] = useState([]);
  const [outstandingReport, setOutstandingReport] = useState([]);
  const [Class, setClass] = useState("");
  const [Section, setSection] = useState("");
  const { isLoading, startLoading, stopLoading } = useLoading();
  const [selectedFeeheads, setSelectedFeeHeads] = useState([]);
  const [feeheadsOptions, setfeeheadOptions] = useState([]);
  const handleClear = () => {
    setClass("");
    setFeeHeadsList([]);
    setOutstandingReport([]);
    setSelectedFeeHeads([]);
  };

  const classList = allClass?.payload?.map((list) => {
    return {
      label: list.class_name,
      value: list.id,
    };
  });

  const sectionList = allSection?.payload?.map((list) => {
    return {
      label: list.section,
      value: list.id,
    };
  });

  useEffect(() => {
    const body = {
      class_id: Number(Class?.value),
    };
    dispatch(getSection(body));
  }, [dispatch, Class]);

  const discountOptions = async () => {
    let data = {
      school_id: Number(user_detail.fk_school_id),
    };
    try {
      let response = await feeDiscountAPI.getFeesHeadsList(data);
      if (response.status === 200) {
        let data = response.data.heads_list;

        setfeeheadOptions(data);
      } else {
        Swal.fire({
          text: response.data.msg,
          icon: "warning",
          allowOutsideClick: false,
        });
      }
    } catch (err) {
      console.error(err);
    } finally {
    }
  };

  useEffect(() => {
    discountOptions();
  }, []);

  const fetchData = async () => {
    if (!Class?.label) {
      Swal.fire({
        title: "",
        text: "Please Select Class.",
        icon: "warning",
        allowOutsideClick: false,
      });
      return;
    }

    const body = {
      academic_id: session_id,
      school_id: school_id,
      class_id: Class?.value,
      heads_name: selectedFeeheads,
    };
    startLoading();
    try {
      let response = await feeDiscountAPI.getFeeOutstandingReport(body);
      if (response.data.status === 200) {
        setFeeHeadsList(response.data.fees_heads_data);
        setOutstandingReport(response.data.outstanding_report);
        if (response.data.outstanding_report.length === 0) {
          Swal.fire({
            text: "No record found.",
            icon: "warning",
            title: "ALERT",
            allowOutsideClick: false,
          });
        }
      }
      if (response.data.status === 500) {
        Swal.fire({
          text: response.data.msg,
          icon: "warning",
          allowOutsideClick: false,
        });
      }
      if (response.data.status === 403) {
        Swal.fire({
          text: response.data.msg,
          icon: "error",
          allowOutsideClick: false,
        });
      }
    } catch (err) {
      console.error(err);
    } finally {
      stopLoading();
    }
  };

  const handleFetchData = () => {
    fetchData();
  };

  const formatDate = (date) => {
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };
  const today = new Date();
  const formattedDate = formatDate(today);

  const totalColumnTotals = new Array(feeHeadsList?.length).fill(0);
  const total2 = new Array(feeHeadsList?.length).fill(0);
  const isTotal = [];
  const totalRowTotals = [];
  let totalBalance1 = 0;
  let columnTotals = 0;

  const HeadList = feeheadsOptions?.map((list) => {
    return {
      label: list.name,
      value: list.id,
    };
  });




  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <div className="section-body">
        <div className="container-fluid">
          <Heading pageTitle={"Fee Outstanding Report"}>
            
          </Heading>
        </div>
      </div>
      <br />
      <div className="section-body mt-4">
        <div className="container-fluid">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-12 my-1">
                  <div className="row">
                    <label className="col-form-label col-md-2">
                      Class<span className="text-danger">*</span>
                    </label>
                    <div className="col-md-9">
                      <Select
                        options={classList}
                        placeholder="Select Class"
                        value={Class}
                        onChange={(e) => setClass(e)}
                        isSearchable={true}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 my-1">
                  <div className="row">
                    <label className="col-form-label col-auto">
                      Select Fees Head <span className="text-danger">*</span>
                    </label>
                    <div className="col-md-8">
                      <Select
                        isMulti
                        placeholder={"Select Fees Head "}
                        options={HeadList}
                        value={
                          selectedFeeheads.length
                            ? HeadList.filter((a) =>
                                selectedFeeheads.includes(a.label) 
                              )
                            : null
                        } // Adjust value for multi-select
                        onChange={(selectedOptions) => {
                          const selectedValues = selectedOptions.map(
                            (option) => option.label
                          );
                          setSelectedFeeHeads(selectedValues);
                        }}
                        isSearchable={true}
                      />
                    </div>
                  </div>
                </div>

             
              </div>
              <div className="row mt-1" style={{ justifySelf: "end" }}>
              <div>
                <button
                  className="btn btn-outline-secondary btn-sm mx-1"
                  onClick={handleFetchData}
                >
                  <i class="fa fa-eye"></i> View
                </button>
              </div>
              <div>
              <button
                type="button"
                className="btn btn-outline-danger mx-2 btn-sm"
                onClick={handleClear}
              >
                <i className="fa fa-times px-1"></i>
                CLEAR
              </button>
              </div>
              {outstandingReport?.length > 0 && (
                    <ExportButton id="test-table-xls-button" />
                  )}
            </div>

{/* 
              <div className="col-lg-2 col-md-6 col-sm-6 my-1 text-right">
               
                </div> */}



            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-header">
                  <h3 className="card-title">Fee Summary</h3>
                </div>
                <div className="card-body">
                  <div className="table-responsive">
                    {outstandingReport && outstandingReport?.length > 0 ? (
                      <table
                        className="table table-bordered table-hover text-nowrap table-sm"
                        id="table-to-xls"
                      >
                        <thead>
                          <tr className="text-center">
                            <th> Sr No</th>
                            <th>Student Name</th>
                            {/* <th>Student Status</th>
                            <th>Concession</th> */}
                            {feeHeadsList?.map((head, index) => (
                              <th key={index}>{head.fk_feeshead__name}</th>
                            ))}
                            <th>Total Paid</th>
                            <th>Total Balance</th>
                          </tr>
                        </thead>
                        <tbody>
                          {outstandingReport?.map((stu, index) => {
                            let Class_Name = Object.keys(stu)[0];
                            let class_students = stu[Class_Name];
                            const columnTotals = new Array(
                              feeHeadsList.length
                            ).fill(0);
                            const dummy = [];
                            const totalPaidAmounts = [];

                            return (
                              <>
                                <tr className="table-secondary text-success">
                                  <td colSpan={6 + feeHeadsList.length}>
                                    Class Section:{" "}
                                    <span className="">{`${Class?.label}-${Class_Name} `}</span>
                                  </td>
                                </tr>

                                {class_students?.map((st, dIndex) => {
                                  const rowClassName =
                                    st.student_status === "Cancelled"
                                      ? "table-danger"
                                      : "";
                                  // Filter fees_heads_data based on fk_feeshead__name match
                                  const matchingFees = st.fees_heads_data.filter((s) =>
                                    feeHeadsList.some((head) => head.fk_feeshead__name === s.fee_heads_name)
                                  );
                                  
                                  // Only proceed if the student's status is not "Cancelled"
                                  if (st.student_status !== "Cancelled") {
                                    // Update columnTotals for matching fees heads
                                    matchingFees.forEach((s, cIndex) => {
                                      if (!isNaN(Number(s.balance_fee))) {
                                        columnTotals[cIndex] += Number(s.balance_fee);
                                      }
                                    });
                                }
                                

                                  if (st.student_status !== "Cancelled") {
                                    totalPaidAmounts.push(
                                      Number(st.total_paid_amount)
                                    );
                                  }
                                  if (st.student_status !== "Cancelled") {
                                    dummy.push(Number(st.total_balance_amount));
                                  }

                                  return (
                                    <tr
                                      className={`text-center ${rowClassName}`}
                                      key={dIndex}
                                    >
                                      <td>{dIndex + 1}</td>
                                      <td className="text-left">
                                        {st.stu_name}
                                      </td>
                                      {/* <td>{st.student_status}</td>
                                      <td>{st.concession}</td> */}
                                      {feeHeadsList.map((head, cIndex) => {
                                        const feeData = matchingFees.find(
                                          (f) =>
                                            f.fee_heads_name ===
                                            head.fk_feeshead__name
                                        );
                                        return (
                                          <td
                                            className="text-right"
                                            key={cIndex}
                                          >
                                            {feeData
                                              ? feeData.balance_fee
                                              : "0"}
                                          </td>
                                        );
                                      })}
                                      <td className="text-right">
                                        {st.total_paid_amount}
                                      </td>
                                      <td className="text-right">
                                        {st.total_balance_amount}
                                      </td>
                                    </tr>
                                  );
                                })}
                                <tr className="text-right text-primary">
                                  <td colSpan="2">Total:1</td>

                                  {columnTotals?.map((total, cIndex) => (
                                    <td key={cIndex}>{`${total}`}</td>
                                  ))}

                                  {columnTotals?.forEach((total, cIndex) => {
                                    totalColumnTotals[cIndex] += total;
                                  })}

                                  {totalPaidAmounts?.forEach(
                                    (total, cIndex) => {
                                      total2[cIndex] += total;
                                    }
                                  )}
                                  <td>
                                    {totalPaidAmounts.reduce(
                                      (a, b) => a + b,
                                      0
                                    )}{" "}
                                  </td>

                                  <td>
                                    {columnTotals?.reduce((a, b) => a + b)}
                                  </td>
                                </tr>
                              </>
                            );
                          })}
                          <tr className="text-right text-danger">
                            <td colSpan="2">Total:2</td>
                            {totalColumnTotals?.map((total, cIndex) => (
                              <td key={cIndex} className="fw-bolder h5">
                                <b>{`${total}`}</b>
                              </td>
                            ))}<td>{total2.reduce((a, b) => a + (b || 0), 0)}</td>

                            <td>
                              {totalColumnTotals?.reduce((a, b) => a + b)}
                            </td>
                          </tr>
                          <tr></tr>
                        </tbody>
                      </table>
                    ) : (
                      ""
                    )}
                  </div>
                  {outstandingReport?.length > 0 && (
                    <h6 className="text-red mt-2">
                      *Note : Cancelled admission student fee is not added to
                      the Total Fee
                    </h6>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FeeOutstandingReport;
