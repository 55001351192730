import React, { useState } from "react";
import MapModal from "./GoogleMap/MapModal";

const BusTrakingList = ({ bus_list }) => {
  console.log(bus_list);

  const [lat, setLat] = useState(0);
  const [lng, setLng] = useState(0);
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const viewModal = (item) => {
    setLat(Number(item.current_lat)); // Convert to number
    setLng(Number(item.current_lng)); // Convert to number
    setTimeout(() => setShow(true), 0); // Ensure setShow receives true
  };


  const getStatusColor = (status) => {
    switch (status.toLowerCase()) {
      case 'parking':
        return 'text-danger'; // Red color for "Parked"
      case 'running':
        return 'text-success'; // Green color for "Running"
      default:
        return 'text-warning'; // Yellow color for any other status
    }
  };

  return (
    <div className="table-responsive">
      <table
        className="table table-striped table-bordered table-hover table-sm"
        id="table-to-xls"
      >
        <thead>
          <tr className="text-center text-nowrap">
            <th>Sr No</th>
            <th>Route Name</th>
            <th>Bus Number</th>
            <th>Status</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {bus_list?.map((item, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>{item.route_name}</td>
              <td>{item.bus_no}</td>
              <td  className={getStatusColor(item.is_started)}>{item.is_started}</td>
              <td className="text-center">
                <button
                  disabled={item.is_started === "Parking"}
                  class="btn btn-outline-secondary btn-sm mx-1"
                  onClick={() => viewModal(item)}
                >
                  <i class="fa fa-eye"></i> View
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <MapModal show={show} handleClose={handleClose} lat={lat} lng={lng} />
    </div>
  );
};

export default BusTrakingList;

// [
//   {
//       "route_name": "Shatabdi Square",
//       "is_started": "Parking",
//       "current_lng": "",
//       "current_lat": "",
//       "bus_no": "MH31-4156-AZ"
//   },
//   {
//       "route_name": "Godhni",
//       "is_started": "Parking",
//       "current_lng": "",
//       "current_lat": "",
//       "bus_no": "MH31EX1234"
//   },
//   {
//       "route_name": "",
//       "is_started": "",
//       "current_lng": "",
//       "current_lat": "",
//       "bus_no": "MH31EX1234"
//   },
//   {
//       "route_name": "",
//       "is_started": "",
//       "current_lng": "",
//       "current_lat": "",
//       "bus_no": "MH31EX5544"
//   },
//   {
//       "route_name": "",
//       "is_started": "",
//       "current_lng": "",
//       "current_lat": "",
//       "bus_no": "MH31EX5544"
//   },
//   {
//       "route_name": "",
//       "is_started": "",
//       "current_lng": "",
//       "current_lat": "",
//       "bus_no": "MH31EX5544"
//   },
//   {
//       "route_name": "",
//       "is_started": "",
//       "current_lng": "",
//       "current_lat": "",
//       "bus_no": "MH31EX5544"
//   },
//   {
//       "route_name": "",
//       "is_started": "",
//       "current_lng": "",
//       "current_lat": "",
//       "bus_no": "MH31EX5544"
//   }
// ]
