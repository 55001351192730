import { useParams } from "react-router-dom";
import React, { useState, useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { SessionContext } from "../../../context/sessionContext";
import { viewProspectus } from "../../../actions/Admission/ProspectusAction";
import moment from "moment";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import useSessionRedirect from "../Comman/SessionChange";
import Swal from "sweetalert2";

const ProspectusReport = () => {
  const token = localStorage.getItem("token");
  const school_user = JSON.parse(localStorage.getItem("school_user"));
  const { id, user_type, name, user_detail } = school_user;
  let s_id = user_detail?.fk_school_id;

  const session_id = useSessionRedirect();
  const { Prospectus } = useSelector((state) => state.viewProspectus);
  const [prospectusData, setProspectusData] = useState(Prospectus);
  const [PaymentMode, setPaymentMode] = useState();



  useEffect(() => {
    setProspectusData(Prospectus);
  }, [Prospectus]);

  const handleClear = () => {
    setProspectusData("");
  };

  const handleClear2 = () => {
    setProspectusData("");
    setPaymentMode(pay_mode[0]);
    setformData({
      payment_mode: "All",
    })
  };

  const dispatch = useDispatch();

  const { uid } = useParams();
  const [student_uid, setstudent_uid] = useState(uid);
  const [receiptDate, setreceiptDate] = useState(
    new Date().toISOString().substr(0, 10)
  );
  const pay_mode = [
    {
      label: "All",
      value: "All",
    },
    {
      label: "Cash",
      value: "Cash",
    },
    {
      label: "Bank",
      value: "Bank",
    },
    {
      label: "UPI",
      value: "UPI",
    },
  ];

  const PaymentList = pay_mode?.map((item) => {
    return {
      label: item.label,
      value: item.value,
    };
  });
  function handlePaymentMode(data) {
    setPaymentMode(data);
  }

  const currentDate = new Date();
  const oneMonthAgo = new Date();
  oneMonthAgo.setMonth(currentDate.getMonth() - 1);

  const [from_date, setFrom_date] = useState(
    oneMonthAgo.toISOString().substr(0, 10)
  );
  const [to_date, setTo_date] = useState(
    new Date().toISOString().substr(0, 10)
  );

  const [formData, setformData] = useState({
    academic_id: session_id,
    school_id: Number(user_detail.fk_school_id),
    from_date: from_date,
    to_date: to_date,
    payment_mode: "All",
  });

  const GetProspectus = () => {
    handleClear();
    const body = {
      ...formData,
      from_date,
      to_date,
    };

    try {
      dispatch(viewProspectus(body));
      setData(Prospectus);
    } catch (error) {
      console.log(error);
    }
  };


  // useEffect(() => {
  //   GetProspectus();
  // }, [from_date, to_date, PaymentMode, session_id]);

  function convertDate(data) {
    const parsedDate = moment(data, "YYYY-MM-DD");
    const formattedDate = parsedDate.format("DD-MM-YYYY");
    return formattedDate;
  }

  return (
    <div className="container-fluid">
      <div className="d-flex justify-content-between align-items-center mx-1">
        <div className="header-action">
          <h1 className="page-title">Prospectus Report</h1>
          {/* <ol className="breadcrumb page-breadcrumb">
            <li className="breadcrumb-item active" aria-current="page">
              Use following filter to Generate report
            </li>
          </ol> */}
        </div>
      </div>
      <div className="tab-pane active mt-3" id="Student-report">
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-lg-3 col-md-6 col-sm-6 my-1">
                <div className={`input-group`}>
                  <label className="col-form-label mr-2">From Date</label>
                  <input
                    type="date"
                    className="form-control"
                    value={from_date}
                    onChange={(e) => setFrom_date(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-6 my-1">
                <div className={`input-group`}>
                  <label className="col-form-label  mr-2">To Date</label>
                  <input
                    type="date"
                    className="form-control"
                    value={to_date}
                    onChange={(e) => setTo_date(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6 my-1">
                <div className="input-group">
                  <label className="col-form-label  mr-2">Payment Mode</label>
                  <Select
                    options={PaymentList}
                    placeholder="Payment Mode"
                    value={PaymentMode}
                    defaultValue={PaymentList[0]}
                    onChange={(event) => {
                      handlePaymentMode(event);
                      setformData((prevState) => ({
                        ...prevState,
                        payment_mode: event.value,
                      }));
                    }}
                    isSearchable={true}
                    className="form-control m-0 p-0"
                  />
                </div>
              </div>
              {/* <div className="col-lg-2 col-md-6 col-sm-6 my-1 m-auto">
                <button
                  className="btn btn-outline-secondary"
                  onClick={GetProspectus}
                  type="button"
                >
                  <i class="fa fa-eye"></i> View
                </button>
              </div> */}
            </div>
            <div className="row mt-1" style={{ justifySelf: "end" }}>
              <div>
                <button
                  className="btn btn-outline-secondary btn-sm mx-1"
                  onClick={GetProspectus}
                >
                  <i class="fa fa-eye"></i> View
                </button>
              </div>
              <div>
              <button
                type="button"
                className="btn btn-outline-danger mx-2 btn-sm"
                onClick={handleClear2}
              >
                <i className="fa fa-times px-1"></i>
                CLEAR
              </button>
              </div>
           
            </div>
          </div>
        </div>
      </div>
      <div className="card-body">
        <div className="table-responsive">
          <table
            className="table table-striped table-sm text-nowrap text-center"
            id="table-to-xls"
          >
            <thead>
              <tr>
                <th>Sr No</th>
                <th>Prospectus No.</th>
                <th>Student Name</th>
                <th>Mobile No</th>
                <th>Issue Date</th>
                <th>Amount</th>
                <th>Payment Mode</th>
                <th>Bank Name</th>
                <th>Payment No.</th>
                <th>Payment Date</th>
                <th>Remark</th>
              </tr>
            </thead>
            <tbody>
              {prospectusData?.payload && prospectusData.payload.length > 0 ? (
                prospectusData.payload.map((list, i) => (
                  <tr key={list.id}>
                    <td>{i + 1}.</td>
                    <td>{list.pros_number}</td>
                    <td className="text-left">{list.student_name}</td>
                    <td>{list.contact_no}</td>
                    <td>{convertDate(list.pros_date)}</td>
                    <td>{list.amount}</td>
                    <td>{list.payment_mode}</td>
                    <td>{list.bank_name}</td>
                    <td>{list.pay_no}</td>
                    <td>{convertDate(list.pay_date)}</td>
                    <td>{list.remark}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="11" className="text-center">
                    <p
                      className="no-list-message"
                      style={{ fontSize: "2.5rem" }}
                    >
                      No Data Found !{" "}
                    </p>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ProspectusReport;
