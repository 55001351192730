import axios from "axios";
import Swal from "sweetalert2";
import { BASE_URL } from "../../Config/BaseUrl";
import {
  ADD_ADMISSION,
  ADMISSION_FAIELD,
  ADMISSION_REQUIST,
  GET_STUDENT_NAME_REQUEST,
  GET_STUDENT_NAME,
  GET_STUDENT_NAME_FAIELD,
  GET_STUDENT_COUNT_REQUEST,
  GET_STUDENT_COUNT,
  GET_STUDENT_COUNT_FAIELD,
  GET_REGULAR_FEES_REQUEST,
  GET_REGULAR_FEES,
  GET_REGULAR_FEES_FAIELD,
  GET_REGULAR_FEES_RECEIPT_REQUEST,
  GET_REGULAR_FEES_RECEIPT,
  GET_REGULAR_FEES_RECEIPT_FAIELD,
  GET_SPECIAL_FEES_RECEIPT_REQUEST,
  GET_SPECIAL_FEES_RECEIPT,
  GET_SPECIAL_FEES_RECEIPT_FAIELD,
  GET_SPECIAL_FEES_TYPE_REQUEST,
  GET_SPECIAL_FEES_TYPE,
  GET_SPECIAL_FEES_TYPE_FAIELD,
  ADMISSION_CANCEL_REQUIST,
  ADMISSION_CANCEL_SUCCESS,
  ADMISSION_CANCEL_FAIELD,
  ADMISSION_STUDENT_DATA_REQUIST,
  ADMISSION_STUDENT_DATA_SUCCESS,
  ADMISSION_STUDENT_DATA_FAIELD,
  ADMISSION_UPDATE_REQUIST,
  ADMISSION_UPDATE_SUCCESS,
  ADMISSION_UPDATE_FAIELD,
  Academic_Filter_Student_List_REQUIST,
  Academic_Filter_Student_List_SUCCESS,
  Academic_Filter_Student_List_FAIELD,
  CLEAR_ERROR,
} from "../../Constants/Admission/Admission";

const token = localStorage.getItem("token");
const school_user = JSON.parse(localStorage.getItem("school_user"));
const regularFeesUrl = "/regular-fees";
let isPresent = school_user?.school_modules?.find((modu) => {
  return modu?.menus_data?.some((menu) => menu?.page_url === regularFeesUrl) ;
});

const config = {
  headers: {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  },
};

export const addAdmission = (body, navigate,handleClear) => async (dispatch) => {
  try {
    dispatch({ type: ADMISSION_REQUIST });
    const response = await axios.post(
      `${BASE_URL}student_admission_registration/`,
      body,
      config
    );
    const data = response.data;
    dispatch({ type: ADD_ADMISSION, payload: data });

    if (response.data.status === 200) {
      Swal.fire({
        text: response.data.msg,
        icon: "success",
        imageAlt: "success image",
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire({
            title: "CONFIRMATION",
            text: "Do you want to Proceed for Fee Receipt?",
            icon: "question",
            showDenyButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#f46a6a",
            confirmButtonText: "Yes",
            denyButtonText: `No`,
            allowOutsideClick: false,
          }).then((result) => {
            if (result.isConfirmed) {
              navigate(
                isPresent
                  ? `/school${regularFeesUrl}/${body.student_code}`
                  : `/school/student-admission`
              );
            } else if (result.isDenied) {
              navigate(`/school/student-admission`);
            }
          });
        }
      });
      handleClear()
    }
    if (response.data.status === 403) {
      Swal.fire({
        text: response.data.msg,
        icon: "error",
        imageAlt: "error image",
        allowOutsideClick: false,
      });
    }
    return data;
  } catch (error) {
    dispatch({ type: ADMISSION_FAIELD, payload: error });
  }
};

export const UpdateAdmission = (body, navigate) => async (dispatch) => {
  try {
    dispatch({ type: ADMISSION_UPDATE_REQUIST });
    const response = await axios.post(
      `${BASE_URL}update_student_admission_registration/`,
      body,
      config
    );
    const data = response.data;
    dispatch({ type: ADMISSION_UPDATE_SUCCESS, payload: data });
    if (response.data.status === 200) {
      Swal.fire({
        text: response.data.msg,
        icon: "success",
        imageAlt: "success image",
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire({
            title: "CONFIRMATION",
            text: "Do you want to Proceed for Fee Receipt?",
            icon: "question",
            showDenyButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#f46a6a",
            confirmButtonText: "Yes",
            denyButtonText: `No`,
            allowOutsideClick: false,
          }).then((result) => {
            if (result.isConfirmed) {
              navigate(
                isPresent
                  ? `/school/regular-fees/${body.student_code}`
                  : `/school/student-admission`,
                {
                  state: {
                    student_code: body.student_code,
                  },
                }
              );
            } else if (result.isDenied) {
              navigate(`/school/student-admission`);
            }
          });
        }
      });
      handleClear()
    }
    if (response.data.status === 403) {
      Swal.fire({
        text: response.data.msg,
        icon: "error",
        imageAlt: "error image",
        allowOutsideClick: false,
      });
    }
  } catch (error) {
    dispatch({ type: ADMISSION_UPDATE_FAIELD, payload: error });
  }
};

export const getStudentName = (body, handleClear) => async (dispatch) => {
  try {
    dispatch({ type: GET_STUDENT_NAME_REQUEST });
    const response = await axios.post(
      `${BASE_URL}search_using_student_id/`,
      body,
      config
    );
    const data = response.data;
    if (response.data.status === 200) {
      dispatch({
        type: GET_STUDENT_NAME,
        payload: data,
        errorCode: null,
        message: null,
      });
    }
    // else if (response.data.status === 500) {
    //   dispatch({
    //     type: CLEAR_ERROR,
    //     errorCode: 500,
    //     message: response.data.msg,
    //   });

    //   console.log(response.data);
    // } else if (response.data.status === 403) {
    //   dispatch({
    //     type: CLEAR_ERROR,
    //     errorCode: 403,
    //     message: response.data.msg,
    //   });
    // }
  } catch (error) {
    dispatch({ type: GET_STUDENT_NAME_FAIELD, payload: error });
  }
};

export const getAdmissionCount = (body) => async (dispatch) => {
  try {
    dispatch({ type: GET_STUDENT_COUNT_REQUEST });
    const response = await axios.post(
      `${BASE_URL}admission_count/`,
      body,
      config
    );
    const data = response.data;
    if (response.data.status === 200) {
      dispatch({ type: GET_STUDENT_COUNT, payload: data });
    }
  } catch (error) {
    dispatch({ type: GET_STUDENT_COUNT_FAIELD, payload: error });
  }
};

export const getRegularFees = (body) => async (dispatch) => {
  try {
    dispatch({ type: GET_REGULAR_FEES_REQUEST });
    const response = await axios.post(
      `${BASE_URL}get_student_fees_details/`,
      body,
      config
    );
    const data = response.data;
    dispatch({ type: GET_REGULAR_FEES, payload: data });
    if (response.data.status === 200) {
    } else if (response.data.status === 403) {
      // if (body.student_id === "") {
      //   console.log(body.student_id);
      // } else {
      //   Swal.fire({
      //     text: response.data.msg,
      //     icon: "error",
      //     imageAlt: "error image",
      //   }).then((result) => {
      //     if (result.isConfirmed) {
      //       console.log(result);
      //     }
      //   });
      // }
    }
  } catch (error) {
    dispatch({ type: GET_REGULAR_FEES_FAIELD, payload: error });
  }
};

export const regularfeesReceipt = (body) => async (dispatch) => {
  try {
    dispatch({ type: GET_REGULAR_FEES_RECEIPT_REQUEST });
    const response = await axios.post(
      `${BASE_URL}save_regular_fees_receipt/`,
      body,
      config
    );
    const data = response.data;
    if (response.data.status === 200) {
      dispatch({ type: GET_REGULAR_FEES_RECEIPT, payload: data });
    }
  } catch (error) {
    dispatch({ type: GET_REGULAR_FEES_RECEIPT_FAIELD, payload: error });
  }
};

export const getspecialFeesTypes = (body) => async (dispatch) => {
  try {
    dispatch({ type: GET_SPECIAL_FEES_TYPE_REQUEST });
    const response = await axios.post(
      `${BASE_URL}get_special_fees_types/`,
      body,
      config
    );
    const data = response.data;
    if (response.data.status === 200) {
      dispatch({ type: GET_SPECIAL_FEES_TYPE, payload: data });
    }
  } catch (error) {
    dispatch({ type: GET_SPECIAL_FEES_TYPE_FAIELD, payload: error });
  }
};

export const specialfeesReceipt = (body, alert) => async (dispatch) => {
  try {
    dispatch({ type: GET_SPECIAL_FEES_RECEIPT_REQUEST });
    const response = await axios.post(
      `${BASE_URL}save_special_fees_receipt/`,
      body,
      config
    );
    const data = response.data;
    dispatch({ type: GET_SPECIAL_FEES_RECEIPT, payload: data });
    if (response.data.status === 200) {
      // alert.success(response.data.msg);
    } else {
      Swal.fire({
        text: response.data.msg,
        icon: "error",
        imageAlt: "error image",
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
        }
      });
    }
  } catch (error) {
    dispatch({ type: GET_SPECIAL_FEES_RECEIPT_FAIELD, payload: error });
  }
};

export const studentDataByCode = (body) => async (dispatch) => {
  try {
    dispatch({ type: ADMISSION_STUDENT_DATA_REQUIST });
    const response = await axios.post(
      `${BASE_URL}get_student_data_using_code/`,
      body,
      config
    );
    const data = response.data;
    dispatch({ type: ADMISSION_STUDENT_DATA_SUCCESS, payload: data });
    if (response.data.status === 500) {
      dispatch({
        type: CLEAR_ERROR,
        errorCode: 500,
        message: response.data.msg,
      });
    } else if (response.data.status === 403) {
      dispatch({
        type: CLEAR_ERROR,
        errorCode: 403,
        message: response.data.msg,
      });
    }
  } catch (error) {
    dispatch({ type: ADMISSION_STUDENT_DATA_FAIELD, payload: error });
  }
};

export const cancelAdmission = (body) => async (dispatch) => {
  try {
    dispatch({ type: ADMISSION_CANCEL_REQUIST });
    const response = await axios.post(
      `${BASE_URL}cancel_student_admission/`,
      body,
      config
    );
    const data = response.data;
    dispatch({ type: ADMISSION_CANCEL_SUCCESS, payload: data });
    if (response.data.status === 200) {
      Swal.fire({
        text: response.data.msg,
        icon: "success",
        imageAlt: "success image",
        allowOutsideClick: false,
      }).then((result) => {});
    } else {
      Swal.fire({
        text: response.data.msg,
        icon: "error",
        imageAlt: "error image",
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
        }
      });
    }
  } catch (error) {
    dispatch({ type: ADMISSION_CANCEL_FAIELD, payload: error });
  }
};

export const getAcademicFilterStudentList = (body) => async (dispatch) => {
  try {
    dispatch({ type: Academic_Filter_Student_List_REQUIST });
    const response = await axios.post(
      `${BASE_URL}get_academic_filter_student_list/`,
      body,
      config
    );
    const data = response.data;
    dispatch({
      type: Academic_Filter_Student_List_SUCCESS,
      payload: data.payload,
    });
    if (response.data.status === 200) {
    } else {
    }
  } catch (error) {
    dispatch({ type: Academic_Filter_Student_List_FAIELD, payload: error });
  }
};
