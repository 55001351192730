import axios from "axios";
import { BASE_URL } from "../../Config/BaseUrl";
const token = localStorage.getItem("token");

export const StaffMasterAPI = {
    resetPassword: async (data) => {
    var axiosConfig = {
      method: "POST",
      url: `${BASE_URL}reset_password_user_api/`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data,
    };
    return await axios(axiosConfig);
  },


  getPickupPointApi: async (data) => {
    var axiosConfig = {
      method: "POST",
      url: `${BASE_URL}get_pickup_point_api/`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data,
    };
    return await axios(axiosConfig);
  },

};
