import {
  GET_DASHBOARD_DATA_FAILURE_FEE,
  GET_DASHBOARD_DATA_REQUEST_FEE,
  GET_DASHBOARD_DATA_SUCCESS_FEE,
} from "../../Constants/Dashboard/Fee";

const initialState = {
  dashboard_fee: [],
  loading: false,
  error: null,
};

export const DashboardReducer_fee = (state = initialState, action) => {
  switch (action.type) {
    case GET_DASHBOARD_DATA_REQUEST_FEE:
      return {
        ...state,
        loading: true,
      };
    case GET_DASHBOARD_DATA_SUCCESS_FEE:
      return {
        ...state,
        dashboard_fee: action.payload,
        loading: false,
      };
    case GET_DASHBOARD_DATA_FAILURE_FEE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    default:
      return state;
  }
};
