export const CREATE_FEEDISCOUNT_REQUEST = "CREATE_FEEDISCOUNT_REQUEST";
export const CREATE_FEEDISCOUNT_SUCCESS = "CREATE_FEEDISCOUNT_SUCCESS";
export const CREATE_FEEDISCOUNT_FAILURE = "CREATE_FEEDISCOUNT_FAILURE";


export const GET_FEECOLLECTION_REQUEST = "GET_FEECOLLECTION_REQUEST";
export const GET_FEECOLLECTION_SUCCESS = "GET_FEECOLLECTION_SUCCESS";
export const GET_FEECOLLECTION_FAILURE = "GET_FEECOLLECTION_FAILURE";


export const GET_SPEC_FEECOLLECTION_REQUEST = "GET_SPEC_FEECOLLECTION_REQUEST";
export const GET_SPEC_FEECOLLECTION_SUCCESS = "GET_SPEC_FEECOLLECTION_SUCCESS";
export const GET_SPEC_FEECOLLECTION_FAILURE = "GET_SPEC_FEECOLLECTION_FAILURE";
export const CLEAR_FEECOLLECTION = "CLEAR_FEECOLLECTION";