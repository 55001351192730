import React, { useState, useEffect, useRef } from "react";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import "react-circular-progressbar/dist/styles.css";
import * as Yup from "yup";
import { useFormik } from "formik";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import useSessionRedirect from "../Comman/SessionChange";
import "./Style.css";

import Loader from "../../Loader/Loader";
import { getSection } from "../../../actions/Admission/StudentReport";
import { SchoolCommAPI } from "../../../api/TeacherDesk/SchoolCommunication";
import ErrorDisplay from "../../CommonComponent/ErrorDisplay";
import EmptyListMessage from "../../CommonComponent/EmptyListMessage";
// import { validateFile } from "../CommonComponent/FileValidationUtil";
import {
  getCast,
  getCategory,
} from "../../../actions/school_user/StudentRegistration";
import { bloodGroupList, genderOptions } from "../../../data/optionData";
import { ClearButton, ViewButton } from "../../CommonComponent/ReusableButton";
import { SelectField } from "../../CommonComponent/SelectField";

const SchoolCommunication = () => {
  const token = localStorage.getItem("token");
  const session_id = useSessionRedirect();
  const school_user = JSON.parse(localStorage.getItem("school_user"));
  let school_id = school_user?.user_detail?.fk_school_id;
  let { school_emp_id, first_name, last_name } = school_user?.user_detail;
  const dispatch = useDispatch();
  const [selectedCategory, setSelectedCategory] = useState(null);
  const { allClass } = useSelector((state) => state.getClass);
  const { allSection } = useSelector((state) => state.getSection);
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const fileInputRef = useRef(null);
  const [Class, setClass] = useState("");
  const [Section, setSection] = useState([]);
  const [studentData, setStudentData] = useState([]);
  const [selectedStudent, setSelectedStudent] = useState([]);
  const { cast, category } = useSelector((state) => state.studentRegistration);
  const { masterData } = useSelector((state) => state.masterData);
  const [categorys, setCategory] = useState([]);
  const [concession, setGetConcessionList] = useState([]);
  const [religion, setReligion] = useState([]);
  const [SMS, setSMS] = useState("");
  const [notification1, setNotification1] = useState("");
  const [message, setMessage] = useState("");
  const [filterType, setFilterType] = useState("Student");
  const [feeFilter, setFeeFilter] = useState("");
  const [isInstallment, setIsInstallment] = useState(false);
  console.log(isInstallment ,"isInstallment")
  const [forMobile, setForMobile] = useState(window.innerWidth < 500);

  const handleCategoryChange = (fieldName, selectOption) => {
    setFilter((prev) => ({ ...prev, [fieldName]: selectOption }));
  };
  const [initialState, setInitialState] = useState({
    "Blood Group": "",
    category: "",
    religion: "",
    concession: "",
    class_id: "",
    section_id: "",
  });
  const [filter, setFilter] = useState(initialState);
  useEffect(() => {
    if (feeFilter === "Hostel") {
      setFilter({ ...filter, Installment: 1 });
    }
  }, [feeFilter]);
  const Fee_mode = [
    {
      label: "Regular Fee",
      value: "Regular",
    },
    {
      label: "Transport Fee",
      value: "Transport",
    },
    {
      label: "Hostel Fee",
      value: "Hostel",
    },
  ];

  const classList = allClass?.payload?.map((list) => {
    return {
      label: list.class_name,
      value: list.id,
    };
  });

  const sectionList = allSection?.payload?.map((list) => {
    return {
      label: list.section,
      value: list.id,
    };
  });
  let categoryOption = category?.payload?.map((c) => {
    return {
      value: c.id,
      label: c.name,
    };
  });
  const religionList = religion?.map((cl) => {
    return {
      label: cl.value,
      value: cl.value,
    };
  });
  const connessionList = concession?.map((cl) => {
    return {
      label: cl.value,
      value: cl.value,
    };
  });

  const installmentOptions = [
    {
      label: "1",
      value: 1,
    },
    {
      label: "2",
      value: 2,
    },
    {
      label: "3",
      value: 3,
    },
    {
      label: "4",
      value: 4,
    },
    {
      label: "5",
      value: 5,
    },
    {
      label: "6",
      value: 6,
    },
    {
      label: "7",
      value: 7,
    },
    {
      label: "8",
      value: 8,
    },
    {
      label: "9",
      value: 9,
    },
    {
      label: "10",
      value: 10,
    },
  ];
  const handelInstallmentchange = () => {
    setFilter("Installment");
    setIsInstallment(false);
    handleClear();
  };
  const handelStudentchange = () => {
    setFilter("Student");
    setIsInstallment(true);
    handleClear();
  };

  useEffect(() => {
    if (token) {
      dispatch(getCategory(token));
    }
  }, [dispatch, token]);
  useEffect(() => {
    const body = {
      class_id: Number(Class?.value),
    };
    if (Class) {
      dispatch(getSection(body));
    }
  }, [dispatch, Class]);

  useEffect(() => {
    setGetConcessionList(
      masterData?.payload?.filter((obj) => obj.name === "Concession")
    );
    setReligion(masterData?.payload?.filter((obj) => obj.name === "Religion"));
  }, [masterData, session_id]);

  const handleSectionSelect = (select) => {
    setSection(select);
  };
  const handleSelectClass = (select) => {
    setClass(select);
    setSection([]);
  };
  const handleChange1 = (event) => {
    const { id, checked } = event.target;

    // Assuming you have state variables for SMS and Notification
    if (id === "smsCheckbox") {
      setSMS(checked ? "True" : "False");
    } else if (id === "notificationCheckbox") {
      setNotification1(checked ? "True" : "False");
    }
  };
  // useEffect(() => {
  //   if (Section?.length > 0) {
  //     fetchStudent();
  //   }
  // }, [session_id, Section]);
  // console.log(selectedStudent)

  const handleClear1 = () => {
    setFilter(initialState);
    setClass("");
    setSection("");
    setStudentData([]);
    setFeeFilter("");
  };
  const handleClear = () => {
    validation.resetForm();
    setSelectAllChecked(false);
    if (fileInputRef.current) {
      fileInputRef.current.value = null;
    }
    setIsEdit(false);
    setClass("");
    setSelectedStudent([]);
    setMessage("");
    setSMS("");
    setNotification1("");
    setStudentData([]);
    setFilter(initialState);
    setFeeFilter("");
  };
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      academic_id: session_id,
      school_id: school_id,
      message: "",
      class_id: Class?.value || "",
      section_id: [],
      student_id: [],
      insert_by: school_emp_id,
    },
    validationSchema: Yup.object().shape({}),

    onSubmit: async (values) => {
      if (message.length === 0) {
        Swal.fire({
          text: "Please enter message.",
          icon: "error",
          allowOutsideClick: false,
        });
        return;
      }
      if (!(SMS === "True" || notification1 === "True")) {
        Swal.fire({
          text: "Please Select at least SMS or Notification",
          icon: "error",
          allowOutsideClick: false,
        });
        return;
      }

      try {
        let students_id = selectedStudent?.map(
          ({ id, fk_section__fk_class, fk_section }) => ({
            id: id,
            class_id: fk_section__fk_class,
            section_id: fk_section,
          })
        );

        if (students_id.length === 0) {
          Swal.fire({
            text: "Please select at least one student.",
            icon: "error",
            allowOutsideClick: false,
          });
          return;
        }
        const sectionIdArray = Section?.value ? [Section.value] : [];
        const data = {
          academic_id: values.academic_id,
          school_id: values.school_id,
          insert_by: 1,
          message: message,
          class_id: values.class_id,
          section_id: sectionIdArray,
          student_id: students_id,
          sms: SMS || "False",
          notification: notification1 || "False",
        };

        const response = await SchoolCommAPI.sendCommMessage(data);
        let responseData = response.data;
        console.log(responseData);
        if (responseData.status === 200) {
          setIsLoading(false);
          Swal.fire({
            text: responseData.msg,
            icon: "success",
            allowOutsideClick: false,
          });

          handleClear();
        } else if (responseData.status === 403) {
          setIsLoading(false);
          Swal.fire({
            text: responseData.msg,
            icon: "error",
            allowOutsideClick: false,
          });
        }
      } catch (error) {
        console.error(error);
      }
    },
  });

  const handelClick = () => {
    setFilterType("Student");
    handelSearch();
  };
  const handelClick2 = () => {
    setFilterType("Installment");
    handelSearch();
  };
  const handleChange = (fieldName, selectOption) => {
    setFilter((prev) => ({ ...prev, [fieldName]: selectOption.value }));
  };
  const handleChange2 = (event) => {
    setMessage(event.target.value);
    validation.handleChange(event);
  };

  let handleCheckboxChange = (index) => {
    const newSelectedStudent = [...selectedStudent];
    const stuSelect = newSelectedStudent?.findIndex(
      (s) => s.id === studentData[index].id
    );
    if (stuSelect === -1) {
      newSelectedStudent.push(studentData[index]);
    } else {
      newSelectedStudent.splice(stuSelect, 1);
    }
    setSelectedStudent(newSelectedStudent);
  };

  const handleSelectAllStudent = () => {
    setSelectAllChecked(!selectAllChecked);
    if (!selectAllChecked) {
      const allStudent = studentData?.map((student) => student);
      setSelectedStudent(allStudent);
    } else {
      setSelectedStudent([]);
    }
  };
  console.log(feeFilter);

  const handelSearch = async () => {
    const data = {
      filter_type: "Student",
      academic_id: session_id,
      class_id: Number(Class?.value) || "",
      section_id: Number(Section?.value) || "",
      school_id: Number(school_id),
      religion: filter["religion"],
      concession: filter["concession"],
      category: filter["category"]?.label,
      blood_group: filter?.["Blood Group"],
      mother_tongue: "",
      caste: "",
      installment: 0,
    };
    console.log(data);
    try {
      let response = await SchoolCommAPI.getStudentListforSchoolCommunication(
        data
      );
      let responseData = response.data;
      console.log(responseData);
      if (responseData.status === 200) {
        setStudentData(responseData.student_data);
      } else if (responseData.status === 403) {
        Swal.fire({
          text: responseData.msg,
          icon: "error",
          allowOutsideClick: false,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handelSearch1 = async () => {
    if (!feeFilter) {
      Swal.fire({
        text: "Please select Fee Type.",
        icon: "error",
        allowOutsideClick: false,
      });
      return;
    }

    let valueforInstallment = filter["Installment"];
    if (feeFilter === "Hostel") {
      valueforInstallment = 1;
    }
    setIsLoading(true);
    const data = {
      fess_type: feeFilter,
      filter_type: "Installment",
      academic_id: session_id,
      class_id: Number(Class?.value) || "",
      section_id: Number(Section?.value) || "",
      school_id: Number(school_id),
      religion: "",
      concession: "",
      category: "",
      blood_group: "",
      mother_tongue: "",
      caste: "",
      installment: valueforInstallment || 1,
    };
    console.log(data);
    try {
      let response = await SchoolCommAPI.getStudentListforSchoolCommunication(
        data
      );
      let responseData = response.data;
      console.log(responseData);
      if (responseData.status === 200) {
        setStudentData(responseData.student_data);
      } else if (responseData.status === 403) {
        Swal.fire({
          text: responseData.msg,
          icon: "error",
          allowOutsideClick: false,
        });
      }
    } catch (error) {
      console.error(error);
    }finally{
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return (
      <>
        <Loader />
      </>
    );
  }
  console.log(isInstallment === "true")
  return (
    <>
      <div className="section-body">
        <ul className="nav nav-tabs page-header-tab justify-content-end mr-2">
          <li className="nav-item">
            <a
              className={`nav-link ${isInstallment === true ? "" : "active"} `}
              
              onClick={handelInstallmentchange}
            >
              Student List
            </a>
          </li>
          <li className="nav-item">
            <a
              className={`nav-link ${isInstallment === true ? "active" : ""} `}
           
              onClick={handelStudentchange}
            >
              Installment Balance
            </a>
          </li>
        </ul>
        <div className="container-fluid">
          <div className="d-flex justify-content-between align-items-center ">
            <div className="header-action">
              <h1 className="page-title">School Communication</h1>
            </div>
          </div>
        </div>
      </div>

      <div className="section-body mt-4">
        <div className="container">
          <div className="tab-content">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="card">
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                  }}
                >
                  <div className="card-body row">
                    <div className="col-lg-12 col-md-12">
                      <div
                        className={`form-group col-md-6 ${
                          forMobile ? "" : "d-flex"
                        }`}
                      >
                        <label className="col-form-label ">
                          Message<span className="text-danger">*</span>
                        </label>
                        <div className="col-md-9">
                          <textarea
                            type="text"
                            className="form-control"
                            placeholder="Message"
                            name="message"
                            value={message}
                            onChange={handleChange2}
                            onBlur={validation.handleBlur}
                            autoComplete="off"
                          />
                          {validation.touched.message &&
                          validation.errors.message ? (
                            <ErrorDisplay
                              errorMsg={validation.errors.message}
                            />
                          ) : null}
                        </div>
                        <div
                          className={`form-group col-md-12 d-flex align-items-center ${
                            forMobile ? "mt-3" : "mt-0"
                          } justify-content-center`}
                        >
                          <div className="col-lg-6">
                            <div class="form-check col-lg-4 ml-2">
                              {/* <input
                                class="form-check-input"
                                type="checkbox"
                                value=""
                                id="smsCheckbox"
                                onChange={handleChange1}
                                checked={SMS === "True"}
                              />
                              <label
                                class="form-check-label"
                                htmlFor="smsCheckbox"
                              >
                                SMS
                              </label> */}
                              <label className="custom-switch">
                                <input
                                  type="checkbox"
                                  className="custom-switch-input"
                                  name="custom-switch-checkbox"
                                  id="smsCheckbox"
                                  onChange={handleChange1}
                                  checked={SMS === "True"}
                                />
                                <span className="custom-switch-indicator mr-2"></span>
                                SMS
                              </label>
                            </div>
                          </div>
                          <div class="form-check col-lg-4 ml-2 ">
                            <label className="custom-switch">
                              <input
                                type="checkbox"
                                className="custom-switch-input"
                                name="custom-switch-checkbox"
                                id="notificationCheckbox"
                                onChange={handleChange1}
                                checked={notification1 === "True"}
                              />
                              <span className="custom-switch-indicator mr-2"></span>
                              Notification
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-12 row">
                      <div className="col-9"></div>
                      <div className="form-grouprow col-3 d-flex justify-content-end">
                        <button
                          type="submit"
                          className="btn btn-sm btn-primary  m-1"
                          style={{ minWidth: "80px" }}
                        >
                          {" "}
                          <i className="fa fa-plus px-1"></i>
                          {isEdit ? "Update" : "Send"}
                        </button>
                        <button
                          type="button"
                          className="btn btn-sm btn-outline-danger   m-1"
                          style={{ minWidth: "80px" }}
                          onClick={() => handleClear()}
                        >
                          {" "}
                          <i className="fa fa-times "></i>
                          Clear
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            {isInstallment === false ? (
              <>
                {" "}
                <div
                  className="col-lg-12 col-md-12 col-sm-12"
                  // id="Filters"
                >
                  <div className="card">
                    <div className="card-header">
                      <h3 className="card-title">Filters</h3>
                    </div>
                    <div className="form-group col-md-12 row d-flex">
                      <div className="col-md-3">
                        <Select
                          name="class_id"
                          options={classList}
                          placeholder="Select Class"
                          value={Class}
                          onChange={handleSelectClass}
                          isSearchable={true}
                        />
                      </div>
                      <div className="col-md-3">
                        <Select
                          options={sectionList}
                          placeholder="Section"
                          value={Section}
                          name="section_id"
                          onChange={handleSectionSelect}
                          isSearchable={true}
                        />
                      </div>{" "}
                      <div className="col-md-3">
                        <SelectField
                          options={connessionList}
                          placeholder="Concession"
                          value={filter["concession"]}
                          onChange={(selectOpt) =>
                            handleChange("concession", selectOpt)
                          }
                        />
                      </div>{" "}
                      <div className="col-md-3">
                        <SelectField
                          options={categoryOption}
                          name="category"
                          placeholder="Category"
                          value={filter["category"]?.value}
                          onChange={(selectOpt) => {
                            handleCategoryChange("category", selectOpt);
                            setSelectedCategory(selectOpt?.value);
                          }}
                        />
                      </div>{" "}
                    </div>
                    <div className="form-group col-md-12 row">
                      <div className="col-md-3">
                        <SelectField
                          options={bloodGroupList}
                          placeholder="Blood Group"
                          value={filter["Blood Group"]}
                          onChange={(selectOpt) =>
                            handleChange("Blood Group", selectOpt)
                          }
                          isSearchable={true}
                        />
                      </div>{" "}
                      <div className="col-md-3">
                        <SelectField
                          options={religionList}
                          placeholder="Religion"
                          value={filter["religion"]}
                          onChange={(selectOpt) =>
                            handleChange("religion", selectOpt)
                          }
                        />
                      </div>{" "}
                    </div>
                    <div className="form-group col-md-12 row">
                      <div className="col-12 d-flex">
                        <div className="col-9"></div>
                        <div className="form-grouprow col-3 d-flex justify-content-end">
                          <ViewButton
                            className={" m-1 "}
                            style={{ minWidth: "80px" }}
                            onClick={handelClick}
                          />
                          {/* <ClearButton className={"m-1 "} style={{minWidth:"80px"}}onClick={handleClear1} /> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header">
                      <h3 className="card-title">Student List</h3>
                    </div>
                    <div className="card-body">
                      <div className="table-responsive">
                        <div style={{ maxHeight: "400px", overflowY: "auto" }}>
                          <table className="table table-hover table-vcenter  table-sm text-nowrap">
                            <thead>
                              <tr className="text-center">
                                <th className="col-md-1">
                                  <input
                                    type="checkbox"
                                    checked={selectAllChecked}
                                    onChange={handleSelectAllStudent}
                                    style={{ cursor: "pointer" }}
                                  />
                                </th>
                                <th className="col-md-1">Sr No</th>
                                <th className="col-md-1">Student Code</th>
                                <th className="col-md-1">Phone Number</th>
                                <th className="col-md-1">Class Section</th>
                                <th>Student Name</th>
                              </tr>
                            </thead>
                            <tbody>
                              {studentData && studentData.length > 0 ? (
                                studentData?.map((stu, index) => (
                                  <tr
                                    key={index}
                                    className={`text-center ${
                                      selectedStudent?.some(
                                        (s) => s.id === stu.id
                                      )
                                        ? "table-active"
                                        : " "
                                    } `}
                                  >
                                    <td>
                                      <input
                                        type="checkbox"
                                        checked={selectedStudent?.some(
                                          (s) => s.id === stu.id
                                        )}
                                        onChange={() =>
                                          handleCheckboxChange(index)
                                        }
                                        style={{ cursor: "pointer" }}
                                      />
                                    </td>
                                    <td>{index + 1}</td>
                                    <td>{stu.fk_student__student_code}</td>
                                    <td>{stu.fk_student__f_contact_no}</td>
                                    <td>{`${stu.class} ${stu.section}`}</td>
                                    <td className="text-left text-wrap">
                                      {stu.fk_student__std_name}
                                    </td>
                                  </tr>
                                ))
                              ) : (
                                <EmptyListMessage
                                  Table="table"
                                  message={"Student Not Available !"}
                                  Span={8}
                                />
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div>
                  <div className="col-lg-12">
                    <div className="card">
                      <div className="card-header">
                        <h3 className="card-title col-lg-9">Installment</h3>
                        <div className="form-grouprow col-lg-3 d-flex justify-content-end">
                          <ViewButton
                            className={"mx-2 "}
                            style={{ minWidth: "80px" }}
                            onClick={handelSearch1}
                          />
                          {/* <ClearButton className={" mx-1 mr-3"} style={{minWidth:"80px"}} onClick={handleClear1} /> */}
                        </div>
                      </div>
                      <div className="form-group col-md-12 row ">
                        <div className="card-body row">
                          <div className="form-group col-md-12 row ">
                            <div className="col-md-3">
                              <label className="col-lg-12 col-form-label">
                                Fee type<span className="text-danger">*</span>
                              </label>
                              <SelectField
                                options={Fee_mode}
                                placeholder=" Fee type"
                                value={feeFilter}
                                onChange={(selectOpt) =>
                                  setFeeFilter(selectOpt.value)
                                }
                              />
                            </div>
                            <div className="col-md-3">
                              <label className="col-lg-12 col-form-label">
                                Installment No{" "}
                                <span className="text-danger">*</span>
                              </label>

                              <SelectField
                                className="col-lg-12"
                                options={installmentOptions}
                                placeholder="Installment No"
                                value={filter["Installment"] || 1} // Set default value to 1 if filter["Installment"] is falsy
                                onChange={(selectOpt) =>
                                  handleChange("Installment", selectOpt)
                                }
                                isDisabled={
                                  feeFilter === "Hostel" ? true : false
                                }
                              />
                            </div>
                            <div className="col-md-3">
                              <label className="col-lg-12 col-form-label">
                                Select Class
                              </label>
                              <Select
                                name="class_id"
                                options={classList}
                                placeholder="Select Class"
                                value={Class}
                                onChange={handleSelectClass}
                                isSearchable={true}
                              />
                            </div>
                            <div className="col-md-3">
                              <label className="col-lg-12 col-form-label">
                                Select Section{" "}
                              </label>
                              <Select
                                options={sectionList}
                                placeholder="Section"
                                value={Section}
                                name="section_id"
                                onChange={handleSectionSelect}
                                isSearchable={true}
                              />
                            </div>
                          </div>
                          <div className="table-responsive">
                            <div
                              style={{ maxHeight: "400px", overflowY: "auto" }}
                            >
                              <table className="table table-hover table-vcenter  table-sm text-nowrap">
                                <thead>
                                  <tr className="text-center">
                                    <th className="">
                                      <input
                                        type="checkbox"
                                        checked={selectAllChecked}
                                        onChange={handleSelectAllStudent}
                                        style={{ cursor: "pointer" }}
                                      />
                                    </th>
                                    <th className="">Sr No</th>
                                    <th className="">Student Code</th>
                                    <th className="">Phone Number</th>
                                    <th>Student Name</th>
                                    <th>Class section</th>
                           
                                    <th className="">total Fees</th>
                                    <th className="">Paid Fees</th>
                                    <th className="">Balance fees</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {studentData && studentData.length > 0 ? (
                                    studentData?.map((stu, index) => (
                                      <tr
                                        key={index}
                                        className={`text-center ${
                                          selectedStudent?.some(
                                            (s) => s.id === stu.id
                                          )
                                            ? "table-active"
                                            : " "
                                        } `}
                                      >
                                        <td>
                                          <input
                                            type="checkbox"
                                            checked={selectedStudent?.some(
                                              (s) => s.id === stu.id
                                            )}
                                            onChange={() =>
                                              handleCheckboxChange(index)
                                            }
                                            style={{ cursor: "pointer" }}
                                          />
                                        </td>
                                        <td>{index + 1}</td>
                                        <td>{stu.fk_student__student_code}</td>
                                        <td>{stu.fk_student__f_contact_no}</td>
                                        <td className="text-left text-wrap">
                                          {stu.fk_student__std_name}
                                        </td>
                                        <td>{`${stu.class} ${stu.section}`}</td>
                                        <td>{stu.total_fess}</td>
                                        <td>{stu.paid_fees}</td>
                                        <td>{stu.balance_fees}</td>
                                      </tr>
                                    ))
                                  ) : (
                                    <EmptyListMessage
                                      Table="table"
                                      message={"Student Not Available !"}
                                      Span={8}
                                    />
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default SchoolCommunication;
