import {
  GET_STUDENT_REG_FAILURE_FOR_DISCOUNT,
  GET_STUDENT_REG_REQUEST_FOR_DISCOUNT,
  GET_STUDENT_REG_SUCCESS_FOR_DISCOUNT,
} from "../../Constants/school_user/ForFeeDiscountStuReg";

const initialState = {
  studentdataforDiscount: null,
  createstudent: null,
  updatestudent: null,
  deletestudent: null,
  loading: false,
  error: null,
  category: null,
  cast: null,
};

export const  ForDiscountSTudentList= (state = initialState, action) => {

  switch (action.type) {
    case GET_STUDENT_REG_REQUEST_FOR_DISCOUNT:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_STUDENT_REG_SUCCESS_FOR_DISCOUNT:
        console.log(state ,"from redux " ,action.payload)
      return {
        ...state,
        studentdataforDiscount: action.payload,
        loading: false,
        error: null,
      };
  

    case GET_STUDENT_REG_FAILURE_FOR_DISCOUNT:
      return {
        ...state,
        loading: true,
        error: action.payload,
      };
    default:
      return state;
  }
};
