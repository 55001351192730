import React, { useEffect, useState ,useRef } from "react";
import Header from "./Header";
import { Link, NavLink, useNavigate } from "react-router-dom";
import Logos from "../../Logo/Logos.svg";

const Sidebar = () => {
  const [menu, setMenu] = useState([]);
  const [widths, setWidths] = useState(window.innerWidth <= 500);

  
  console.error("menu", menu);
  const navigate = useNavigate();
  const path = window.location.pathname;
  const user = JSON.parse(localStorage.getItem("User"));
  const school_user = JSON.parse(localStorage.getItem("school_user"));
  const selectedMenuId = localStorage.getItem("selectedMenuId");
  const selectedModuleId = localStorage.getItem("selectedModuleId");
  const { school_modules } = school_user;
  const [active, setActive] = useState(false);
  console.log(school_modules ,"./././././././././././. school_modules school_modulesschool_modules")

  const [IsAdmin, setIsAdmin] = useState(false);
  const [IsSchool, setIsSchool] = useState(false);
  const [commanName, setcommanName] = useState(false);
  const [menuName, setmenuName] = useState("");
  const [mName, setmName] = useState("");
  const [menuid, setMenuid] = useState("");

  useEffect(() => {
    document.title = active ? `${menuName}` : "Dashboard";
  }, [menuName, active]);

  useEffect(() => {
    switch (user) {
      case "Admin":
        setIsAdmin(true);
        break;

      case "School":
        setIsSchool(true);
        break;
    }
    if (
      selectedMenuId !== null &&
      school_user.id !== null &&
      selectedModuleId !== null
    ) {
      let dataModule = school_user.school_modules
        .map((data) => {
          return data;
        })
        .find((moduleData) => {
          return moduleData.fk_module_id.toString() === selectedModuleId;
        });

      if (dataModule !== null) {
        const { fk_module__name, menus_data } = dataModule;
        setmenuName(fk_module__name);
        setcommanName(true);
        setActive(true);
        setIsSchool(false);
        setMenu(menus_data);
      }
    }
  }, []);
  const currentPageUrl = window.location.href;

  const SuperAdminMenus = [ 
    {
      Id: "1",
      Href: "/admin-dashboard",
      ClassName: "fa fa-dashboard",
      Name: "Dashboard",
    },
    {
      Id: "2",
      Href: "/master",
      ClassName: "fa fa-users",
      Name: "Masters",
    },
    {
      Id: "3",
      Href: "/organization",
      ClassName: "fa fa-users",
      Name: "Organization",
    },
    {
      Id: "4",
      Href: "/session-management",
      ClassName: "fa fa-calendar-check-o",
      Name: "Academic Session",
    },
    {
      Id: "5",
      Href: "/school-registration",
      ClassName: "fa fa-graduation-cap",
      Name: "School Registration",
    },
    {
      Id: "6",
      Href: "/users",
      ClassName: "fa fa-users",
      Name: "Users",
    },
  ];

  useEffect(() => {
    const handleBackButton = () => {
      if (currentPageUrl === "http://localhost:3000/school/dashboard") {
        localStorage.removeItem("selectedModuleId");
        localStorage.removeItem("selectedMenuId");
        setActive(false);
        setcommanName(false);
        setIsSchool(true);
      }

    
    };

    window.addEventListener("popstate", handleBackButton);
    return () => {
      window.removeEventListener("popstate", handleBackButton);
    };
  }, []);

  let ChangeMenu = (ModuleId, ModuleName, menu) => {
    localStorage.setItem("selectedModuleId", ModuleId);
    setmenuName(ModuleName);
    setcommanName(true);
    setActive(true);
    setIsSchool(false);
    if (menu.length > 0) {
      localStorage.setItem("selectedMenuId", menu[0].id);
      document.body.classList.remove("offcanvas-active");
    }
    setMenu(menu);
  };
  let closeNav = () => {
    localStorage.removeItem("selectedModuleId");
    localStorage.removeItem("selectedMenuId");
    setActive(false);
    setcommanName(false);
    setIsSchool(true);
    navigate("/school/dashboard");
  };
  const handelClick = (id) => {
    localStorage.setItem("selectedMenuId", id);
    document.body.classList.remove("offcanvas-active");
  };
  const [isBodyClassAdded, setIsBodyClassAdded] = useState(false);

  const handleClick = () => {
    console.log("Current isBodyClassAdded:", isBodyClassAdded); // Logs the current state
    
    if (isBodyClassAdded) {
      document.body.classList.remove("offcanvas-active");
      console.log("Class removed"); // Logs when the class is removed
    } else {
      document.body.classList.add("offcanvas-active");
      console.log("Class added"); // Logs when the class is added
    }

    setIsBodyClassAdded(!isBodyClassAdded); // Toggles the state
    console.log("New isBodyClassAdded:", !isBodyClassAdded); // Logs the updated state
  };
  const handleClick1 = () => {
    document.body.classList.remove("offcanvas-active");
  };
  const sidebarRef = useRef(null); // Reference to the sidebar

useEffect(() => {


  if(widths){
    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        document.body.classList.remove("offcanvas-active");
        setIsBodyClassAdded(false);
      }
    };
  
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }
 
}, []);

  return (
    <>
      <div id="header_top" className="header_top">
        <div className="container">
          <div className="hleft">
            <Link className="header-brand">
              <img className=" bg-light" src={Logos} alt="Logo" />
            </Link>
            <div className="dropdown">
              <a
                // href="javascript:void(0)"
                className="nav-link icon menu_toggle"
                onClick={handleClick}
              >
                <i className="fe fe-align-center"></i>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div id="left-sidebar" className="sidebar" ref={sidebarRef}>
        <h5 className="brand-name" style={{ fontWeight: "bold" }}>
          VidyaMate
        </h5>
        <ul className="nav nav-tabs">
          {IsAdmin && (
            <li className="nav-item">
              <a
                className={`nav-link ${IsAdmin && "active"} `}
                data-toggle="tab"
                href="#menu-super"
                style={{ fontWeight: "bold" }}
              >
                Super Admin
              </a>
            </li>
          )}
          {IsSchool && (
            <div className="row clearfix row-deck ">
              <div className="col">
                <a
                  className={`nav-link ${IsSchool && "active"} `}
                  data-toggle="tab"
                  href="#menu-school"
                  style={{ fontWeight: "bold" }}
                  onClick={() => closeNav()}
                >
                  {" "}
                  Home
                </a>
              </div>
              <div className="col">
                <a className="nav-link" href="#" onClick={() => closeNav()}>
                  <i className="fa fa-home font-24 text-light"></i>{" "}
                </a>
              </div>
            </div>
          )}
          {commanName && (
            <div className="row clearfix row-deck">
              <div className="col">
                <a
                  className={`nav-link text-nowrap ${commanName && "active"}`}
                  data-toggle="tab"
                  href="#menu-school"
                  onClick={() => closeNav()}
                  style={{ fontWeight: "bold" }}
                >
                  {menuName}
                </a>
              </div>

              <div className="col">
                <a className="nav-link" href="#" onClick={() => closeNav()}>
                  {/* <i className="icon-grid font-16 text-light"></i> */}
                  <i className="fa fa-home font-24 text-light"></i>
                </a>
              </div>
            </div>
          )}
        </ul>
        <div className="tab-content mt-3">
          {IsAdmin && (
            <div
              className={`tab-pane fade ${IsAdmin && "show active"} `}
              id="menu-super"
              role="tabpanel"
              onClick={handleClick1}
            >
              <nav className="sidebar-nav">
                <ul className="metismenu">
                  {SuperAdminMenus.map(({ Id, Href, ClassName, Name }) => (
                    <li
                      key={Id}
                      className={`/admin${Href}` === path && "bg-white pl-2"}
                    >
                      <NavLink to={`/admin${Href}`}>
                        <i
                          className={
                            `/admin${Href}` === path
                              ? `text-info ${ClassName}`
                              : `text-white  ${ClassName}`
                          }
                        ></i>
                        <span
                          className={`/admin${Href}` === path && "text-info h6"}
                          style={{ fontWeight: "bold" }}
                        >
                          {Name}
                        </span>
                      </NavLink>
                    </li>
                  ))}
                </ul>
              </nav>
            </div>
          )}

          {/* {IsSchool && (
            <div
              className={`tab-pane fade ${IsSchool && "show active"}  `}
              id="menu-school"
              role="tabpanel"
            >
              <nav className="sidebar-nav">
                <ul className="metismenu grid d-flex flex-column mb-3 ">
                  {school_modules?.map(
                    ({
                      fk_module__name,
                      fk_module__page_url,
                      fk_module__icon,
                      fk_module_id,
                      menus_data,
                    }) => {
                      
                      if (menus_data?.length !== 0) {
                        return (
                          <li key={fk_module_id} className="menu_li">
                            <NavLink
                              // to={`/school${fk_module__page_url}`}
                              to={`/school${menus_data[0]?.page_url}`}
                              className="w-75 bg-light scale-btn"
                              onClick={() =>
                                ChangeMenu(
                                  fk_module_id,
                                  fk_module__name,
                                  menus_data
                                )
                              }
                            >
                              <i className={`${fk_module__icon}`}></i>
                              <span className="w-100 text-info">
                                {fk_module__name}
                              </span>
                            </NavLink>
                          </li>
                        );
                      } else {
                        return null;
                      }
                    }
                  )}
                </ul>
              </nav>
            </div>
          )} */}
          {IsSchool && (
  <div
    className={`tab-pane fade ${IsSchool && "show active"}`}
    id="menu-school"
    role="tabpanel"
  >
    <nav className="sidebar-nav">
      <ul className="metismenu grid d-flex flex-column mb-3">
        {school_modules?.map(
          ({
            fk_module__name,
            fk_module__page_url,
            fk_module__icon,
            fk_module_id,
            menus_data,
          }) => {
            // Ensure menus_data is a valid array and not empty
            if (Array.isArray(menus_data) && menus_data.length > 0) {
              return (
                <li key={fk_module_id} className="menu_li">
                  <NavLink
                    to={`/school${menus_data[0]?.page_url}`} // Use first menu's page_url
                    className="w-75 bg-light scale-btn"
                    onClick={() =>
                      ChangeMenu(
                        fk_module_id,
                        fk_module__name,
                        menus_data
                      )
                    }
                  >
                    <i className={`${fk_module__icon}`}></i>
                    <span className="w-100 text-info">
                      {fk_module__name}
                    </span>
                  </NavLink>
                </li>
              );
            } else {
              return null; // Skip rendering if no menus_data
            }
          }
        )}
      </ul>
    </nav>
  </div>
)}


          {active && (
            <div
              className={`tab-pane fade ${active && "show active"} `}
              id="menu-super"
              role="tabpanel"
            >
              <nav className="sidebar-nav">
                <ul className="metismenu">
                  {menu?.map(({ sequence, page_url, icon, name, id }) => (
                    <li
                      key={sequence}
                      className={
                        localStorage.getItem("selectedMenuId") == id
                          ? "bg-white pl-2"
                          : ""
                      }
                    >
                      <NavLink
                        to={`/school${page_url}`}
                        onClick={() => handelClick(id, name)}
                      >
                        {/* <i
                            className={
                              `/school${page_url}` === path
                                ? `text-info  ${icon}`
                                : `text-white  ${icon}`
                            }
                          ></i> */}
                        <span
                          style={{ fontWeight: "bold" }}
                          className={
                            localStorage.getItem("selectedMenuId") == id &&
                            "text-info"
                          }
                        >
                          {name}
                        </span>
                      </NavLink>
                    </li>
                  ))}
                </ul>
              </nav>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Sidebar;
