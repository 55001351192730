import React from "react";
import { Table, Modal, ModalHeader, ModalBody } from "reactstrap";

import EmptyListMessage from "../../CommonComponent/EmptyListMessage";

const FeeDiscountTable = ({ isOpen, toggle, feeData, title }) => {
  const feeDataArray = Array.isArray(feeData) ? feeData : [];

  console.log(feeDataArray, "feeDataArray");
  const closeBtn = (
    <button className="btn btn-sm close " onClick={toggle} type="button">
      <i className="fa fa-times" style={{ color: "red" }}></i>
    </button>
  );


  function convertToMMDDYYYY(dateString) {
    const date = new Date(dateString);
  
    if (isNaN(date)) {
return
    }
  
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const year = date.getFullYear();
  
    return `${month}-${day}-${year}`;
  }
  
  // Example usage

  return (
    <Modal isOpen={isOpen} toggle={toggle} fullscreen className="mobel_box">
      <ModalHeader toggle={toggle} close={closeBtn}>
        {title}
      </ModalHeader>
      <ModalBody>
        <div className="table-responsive">
          {feeDataArray && feeDataArray?.length > 0 ? (
            <Table size="sm" className=" table-bordered table-hover">
              <thead className="table-dark">
                <tr className="text-center text-nowrap ">
                  <th>Sr no</th>
                  <th>Discount Date</th>
                  <th>Fee Head name</th>
                  <th>Discount Amount</th>
                </tr>
              </thead>
              <tbody>
                {feeDataArray?.map((stu, index) => (
                  <tr key={index} className="text-center clickable-row">
                    <td>{index + 1}</td>
                    <td>
                      {convertToMMDDYYYY(stu.discount_date)}
                    </td>
                    <td>{stu.heads_name}</td>
                    <td className="text-right">{stu.discount}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <EmptyListMessage
              message={"Discount Details Not Available !"}
              Div="div"
            />
          )}
        </div>
      </ModalBody>
    </Modal>
  );
};

export default FeeDiscountTable;
