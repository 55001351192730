import React, { useState, useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import {
  getClass,
  getSection,
} from "../../../../actions/Admission/StudentReport";
import Swal from "sweetalert2";
import { getMasterData } from "../../../../actions/super-user/MasterData";
import { feeDiscountAPI } from "../../../../api/Admission/feeDiscountAPI";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import useSessionRedirect from "../../Comman/SessionChange";
import { ExportButton } from "../../../CommonComponent/ReusableButton";
import { convertDateInMMDDYY } from "../../../CommonComponent/DateFormatUtil";
const DiscountReport = () => {
  const token = localStorage.getItem("token");
  const school_user = JSON.parse(localStorage.getItem("school_user"));
  const { email, id, user_type, name, user_detail } = school_user;
  const { school_emp_id } = user_detail;
  let school_id = school_user?.user_detail?.fk_school_id;
  const session_id = useSessionRedirect();
  const { allClass } = useSelector((state) => state.getClass);
  const { allSection } = useSelector((state) => state.getSection);
  const { masterData } = useSelector((state) => state.masterData);
  const dispatch = useDispatch();
  const [reportList, setReportList] = useState([]);
  const [totalDiscount, setTotalDiscount] = useState("");
  const [Class, setClass] = useState("");
  const [Section, setSection] = useState("");
  const [getDiscountReason, setGetDiscountReason] = useState([]);
  const [selectDiscountReason, setSelectDiscountReason] = useState("All");
  const [selectedFeeheads, setSelectedFeeHeads] = useState([]);
  const [feeheadsOptions, setfeeheadOptions] = useState([]);

  const handleClear = () => {
    setClass("");
    setSection("");
    setTotalDiscount("");
    setReportList([]);
    setSelectDiscountReason("All");
    setSelectedFeeHeads([]);
  };

  console.error(selectedFeeheads)

  const discountReasonList = getDiscountReason?.map((cl) => ({
    label: cl.value,
    value: cl.id,
  }));
  discountReasonList?.unshift({ label: "All", value: "All" });

  useEffect(() => {
    setGetDiscountReason(
      masterData?.payload?.filter((obj) => obj.name === "DiscountReason")
    );
  }, [masterData, session_id]);

  useEffect(() => {
    if (token) {
      dispatch(getMasterData(token));
    }
  }, [dispatch, token, session_id]);

  const classList = [
    { label: "All", value: "all" }, // First option
    ...allClass?.payload?.map((list) => ({
      label: list.class_name,
      value: list.id,
    })),
  ];
  

  const sectionList = allSection?.payload?.map((list) => {
    return {
      label: list.section,
      value: list.id,
    };
  });


  const HeadList = feeheadsOptions?.map((list) => {
    return {
      label: list.name,
      value: list.id,
    };
  });




  useEffect(() => {
    const body = {
      class_id: Number(Class?.value),
    };
    dispatch(getSection(body));
  }, [dispatch, Class]);

  const discountOptions = async () => {
    let data = {
      school_id: Number(user_detail.fk_school_id),
    };
    try {
      let response = await feeDiscountAPI.getFeesHeadsList(data);
      if (response.status === 200) {
        let data = response.data.heads_list

        setfeeheadOptions(data);
      } else {
        Swal.fire({
          text: response.data.msg,
          icon: "warning",
          allowOutsideClick: false,
        });
      }
    } catch (err) {
      console.error(err);
    } finally {
    }
  };

  useEffect(() => {
    discountOptions();
  }, []);

  const fetchData = async () => {
    if (!Class?.label) {
      Swal.fire({
        title: "",
        text: "Please Select Class.",
        icon: "warning",
        allowOutsideClick: false,
      });
      return;
    }

    const body = {
      academic_id: session_id,
      school_id: Number(user_detail.fk_school_id),
      class: Class?.label,
      section: Section?.label || "All",
      discount_reason: selectDiscountReason,
      heads_name: selectedFeeheads,
    };

    try {
      let response = await feeDiscountAPI.getDiscountReport(body);
      if (response.data.status === 200) {
        setReportList(response.data.discount_report);
        setTotalDiscount(response.data.student_total_discount);
        if (response.data.discount_report.length === 0) {
          Swal.fire({
            text: "No record found.",
            icon: "warning",
            title: "ALERT",
            allowOutsideClick: false,
          });
        }
      }
      if (response.data.status === 500) {
        Swal.fire({
          text: response.data.msg,
          icon: "warning",
          allowOutsideClick: false,
        });
      }
      if (response.data.status === 403) {
        Swal.fire({
          text: response.data.msg,
          icon: "error",
          allowOutsideClick: false,
        });
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleFetchData = () => {
    fetchData();
  };

  const formatDate = (date) => {
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };
  const today = new Date();
  const formattedDate = formatDate(today);

  return (
    <>
      <div className="section-body">
        <div className="container-fluid">
          <div className="d-flex justify-content-between align-items-center">
            <div className="header-action">
              <h1 className="page-title ">Fee Discount Report</h1>
              {/* <ol className="breadcrumb page-breadcrumb">
                <li className="breadcrumb-item active" aria-current="page">
                  Certificates
                </li>
              </ol> */}
            </div>
            {/* <div className=" mt-2">
              <button
                type="button"
                className="btn btn-outline-secondary mx-2"
                onClick={handleFetchData}
              >
                <i class="fa fa-eye"></i> View
              </button>

              <button
                type="button"
                className="btn btn-outline-danger mx-2"
                onClick={handleClear}
              >
                <i className="fa fa-times px-1"></i>
                CLEAR
              </button>
            </div> */}
          </div>
        </div>
      </div>
      <br />
      <div className="section-body mt-4">
        <div className="container-fluid">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-lg-3 col-md-4 ">
                  <label className="col-form-label mx-2">
                    Class<span className="text-danger">*</span>
                  </label>
                  <Select
                    options={classList}
                    placeholder="Select Class"
                    value={Class}
                    onChange={(e) => setClass(e)}
                    isSearchable={true}
                  />
                </div>
                <div className="col-lg-3  ">
                  <label className="col-form-label mx-2">Section</label>
                  <Select
                    options={sectionList}
                    placeholder="Select Section"
                    value={Section}
                    onChange={(e) => setSection(e)}
                    isSearchable={true} 
                  />
                </div>
                <div className="col-lg-3  ">
                  <label className="col-form-label mx-2">Discount Reason</label>
                  <Select
                    options={discountReasonList}
                    placeholder="Discount Reason"
                    value={discountReasonList?.find(
                      (s) => s.value === selectDiscountReason
                    )}
                    onChange={(selectedOption) =>
                      setSelectDiscountReason(selectedOption?.value)
                    }
                  />
                </div>
                <div className="col-lg-3 col-md-6 col-sm-6">
                  <label className="col-form-label mx-2">
                    Select Fees Head
                  </label>
                  <Select
                    isMulti
                    placeholder={"Select Fees Head "}
                    options={HeadList}
                    value={selectedFeeheads.length ? HeadList.filter(
                      (a) => selectedFeeheads.includes(a.label)
                    ) : null}  // Adjust value for multi-select
                    onChange={(selectedOptions) => {
                      const selectedValues = selectedOptions.map(
                        (option) => option.label
                      );
                      setSelectedFeeHeads(selectedValues);
                    }}
                    isSearchable={true}
                  />

                </div>
                <div className="col-lg-3   mt-2">
                  {reportList?.length > 0 ? (
                    <ExportButton id="test-table-xls-button" />
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="row mt-1" style={{ justifySelf: "end" }}>
              <div>
                <button
                  className="btn btn-outline-secondary btn-sm mx-1"
                  onClick={handleFetchData}
                >
                  <i class="fa fa-eye"></i> View
                </button>
              </div>
              <div>
              <button
                type="button"
                className="btn btn-outline-danger mx-2 btn-sm"
                onClick={handleClear}
              >
                <i className="fa fa-times px-1"></i>
                CLEAR
              </button>
              </div>
           
            </div>
            </div>
          </div>

          <div className="card">
            <div className="card-header">
              <h3 className="card-title">Summary</h3>
            </div>
            <div className="card-body">
              <div className="table-responsive">
                <table
                  className="table  table-bordered table-hover table-sm text-nowrap"
                  id="table-to-xls"
                >
                  <thead>
                    <tr className="text-center">
                      <th>Sr No</th>
                      <th>Discount Date</th>
                      <th>Fee Head Name</th>
                      <th>Discount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {reportList?.map((s, index) => (
                      <React.Fragment key={index}>
                        <tr className="table-secondary text-success">
                          <td colSpan="4">
                            Student Name:{" "}
                            <span className="text-uppercase">{`[ ${s.student_code} ] ${s.student_name}`}</span>{" "}
                            Class : {" "} {s?.discount[0]?.class_name}
                          </td>
                        </tr>
                        {s.discount?.map((d, dIndex) => (
                          <tr className="" key={dIndex}>
                            <td>{dIndex + 1}</td>
                            <td>{convertDateInMMDDYY(d.discount_date)}</td>
                            <td>{d.heads_name}</td>
                            <td className="text-right">{d.discount}</td>
                          </tr>
                        ))}
                        <tr className=" text-primary">
                          <td
                            className=""
                            colSpan={1}
                            style={{ color: "SlateBlue" }}
                          >
                            Discount Reason: {s.discount_resason}, Discount Given By:{" "}
                            {s.discount_authority}, Remark: {s.remark}
                          </td>
                          <td colSpan="2" className="text-right">Total:</td>
                          <td className="text-right">{`${s.total_discount}`}</td>
                        </tr>
                      </React.Fragment>
                    ))}
                    {totalDiscount !== "" && (
                      <tr className="text-right text-danger">
                        <td colSpan="3">Total Discount:</td>
                        <td>{`${totalDiscount}`}</td>

                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DiscountReport;
