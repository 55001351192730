import React, { useState, useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { SessionContext } from "../../../context/sessionContext";
import { getStudentAllReport1 } from "../../../actions/Admission/StudentReport";
import {
  getClass,
  getSection,
  getStudentAllReport,
} from "../../../actions/Admission/StudentReport";
import useSessionRedirect from "../Comman/SessionChange";
import useLoading from "../../CommonComponent/useLoading";
import Loader from "../../Loader/Loader";
import { ClearButton, ViewButton } from "../../CommonComponent/ReusableButton";
import { SelectField } from "../../CommonComponent/SelectField";
import { bloodGroupList, genderOptions } from "../../../data/optionData";
import {
  getCast,
  getCategory,
} from "../../../actions/school_user/StudentRegistration";
import EmptyListMessage from "../../CommonComponent/EmptyListMessage";
import ReactHtmlTableToExcel from "react-html-table-to-excel";
import { ExportButton } from "../../CommonComponent/ReusableButton";
import { getStudentAllReport12 } from "../../../actions/Admission/StudentReport";
const StudentReports = () => {
  const token = localStorage.getItem("token");
  const school_user = JSON.parse(localStorage.getItem("school_user"));
  const { id, user_type, name, user_detail } = school_user;
  const { cast, category, createstudent, studentdata } = useSelector(
    (state) => state.studentRegistration
  );
  const { masterData } = useSelector((state) => state.masterData);
  const session_id = useSessionRedirect();
  const { isLoading, startLoading, stopLoading } = useLoading();
  const dispatch = useDispatch();
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [newReport, setNewReport] = useState(false);
  const [getStudentAll, setgetStudentAll] = useState([]);
  const [from_date, setFrom_date] = useState(
    new Date().toISOString().substr(0, 10)
  );
  const [to_date, setTo_date] = useState(
    new Date().toISOString().substr(0, 10)
  );
  const [Class, setClass] = useState("");
  const [Section, setSection] = useState("");
  const { allClass } = useSelector((state) => state.getClass);
  const { allSection } = useSelector((state) => state.getSection);
  const { stduentReport } = useSelector((state) => state.getStudentAllReport);
  const [concession, setGetConcessionList] = useState([]);
  const [religion, setReligion] = useState([]);
  const [Studentsdata, setStudentsData] = useState([]);
  let date = new Date().toISOString().substr(0, 10);
  const [admissionDate, setadmissionDate] = useState(date);
  const { SessionData, Session, setSession, handlesessionselect } =
    useContext(SessionContext);

  const getOneMonthAgo = () => {
    const currentDate = new Date();
    currentDate.setMonth(currentDate.getMonth() - 1);

    // Format the date to match the input's format (yyyy-MM-dd)
    const formattedDate = currentDate.toISOString().split("T")[0];

    return formattedDate;
  };

  const [isChecked, setIsChecked] = useState(false);

  const [initialState, setInitialState] = useState({
    Gender: "",
    "Blood Group": "",
    category: "",
    religion: "",
    concession: "",
    from_date: from_date,
    to_date: to_date,
  });
  const [filter, setFilter] = useState(initialState);
  const sessionId = Session;

  useEffect(() => {
    if (sessionId && SessionData.length > 0) {
      const session = SessionData.find(
        (session) => session.id === parseInt(sessionId)
      );

      if (session) {
        const { start_date, end_date } = session;

        setInitialState({
          ...initialState,
          from_date: start_date,
          to_date: end_date,
        });

        setFilter({
          ...initialState,
          from_date: start_date,
          to_date: end_date,
        });
      }
    }
  }, [SessionData, sessionId]);

  const classList = allClass?.payload?.map((list) => {
    return {
      label: list.class_name,
      value: list.id,
    };
  });

  const sectionList = allSection?.payload?.map((list) => {
    return {
      label: list.section,
      value: list.id,
    };
  });
  useEffect(() => {
    setGetConcessionList(
      masterData?.payload?.filter((obj) => obj.name === "Concession")
    );
    setReligion(masterData?.payload?.filter((obj) => obj.name === "Religion"));
  }, [masterData, session_id]);

  useEffect(() => {
    Class?.value &&
      dispatch(
        getSection({
          class_id: Number(Class?.value),
        })
      );
  }, [dispatch, Class]);

  const StudentAllReport = async (type) => {
    event.preventDefault();

    if (isChecked) {
      const { from_date, to_date } = filter;

      // Check if from_date and to_date are not empty
      if (!from_date || !to_date) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Both From Date and To Date are required!",
        });
        return;
      }

      // Check if to_date is greater than or equal to from_date
      if (new Date(to_date) < new Date(from_date)) {
        Swal.fire({
          icon: "error",
          title: "Invalid Date Range",
          text: "To Date cannot be earlier than From Date!",
        });
        return;
      }
    }
    try {
      startLoading();
      if (type === "New") {
        if (from_date && to_date) {
          const body = {
            academic_id: session_id,
            class_id: Number(Class?.value) || "",
            section_id: Number(Section?.value) || "",
            school_id: Number(user_detail.fk_school_id),
            from_date: "",
            to_date: "",
            admission_type: type,
            is_from_to_date: isChecked ? "True" : "False",
            religion: filter["religion"],
            concession: filter["concession"],
            category: filter["category"]?.label,
            gender: filter?.Gender,
            blood_group: filter?.["Blood Group"],
          };

          if (isChecked) {
            body["from_date"] = filter["from_date"];
            body["to_date"] = filter["to_date"];
          }
          console.log(body);
          const response = await getStudentAllReport12.getStudentAllReport1(
            body
          );
          const responseData = response.data;
          const data = responseData.payload;
          setStudentsData(data);
        }
      } else {
        const body = {
          academic_id: session_id,
          class_id: Number(Class?.value) || "",
          section_id: Number(Section?.value) || "",
          school_id: Number(user_detail.fk_school_id),
          from_date: "",
          to_date: "",
          admission_type: type,
          is_from_to_date: isChecked ? "False" : "True",
          religion: filter["Religion"],
          concession: filter["concession"],
          category: filter["category"]?.label,
          gender: filter?.Gender,
          blood_group: filter?.["Blood Group"],
        };

        if (isChecked) {
          body["from_date"] = filter["from_date"];
          body["to_date"] = filter["to_date"];
        }
        console.log(body);
        const response = await getStudentAllReport12.getStudentAllReport1(body);
        const responseData = response.data.payload;
        const data = responseData;
        setStudentsData(data);
        console.log(data);

        // await dispatch(getStudentAllReport(body));
      }
    } catch (error) {
      console.error(error);
    } finally {
      stopLoading();
    }
  };

  // useEffect(() => {
  //   setStudentsData(stduentReport?.payload);
  // }, [dispatch, ]);

  const handleClear = () => {
    console.log("clicked");
    setFilter(initialState);
    setClass("");
    setSection("");
    setStudentsData([]);
    console.log(Studentsdata);
  };

  // useEffect(() => {
  //   StudentAllReport();
  // }, [dispatch, session_id]);

  let categoryOption = category?.payload?.map((c) => {
    return {
      value: c.id,
      label: c.name,
    };
  });
  let castOption = cast?.payload?.map((c) => {
    return {
      // value: c.id,
      value: c.name,
      label: c.name,
    };
  });
  const connessionList = concession?.map((cl) => {
    return {
      label: cl.value,
      value: cl.value,
    };
  });
  const religionList = religion?.map((cl) => {
    return {
      label: cl.value,
      value: cl.value,
    };
  });
  useEffect(() => {
    if (token) {
      dispatch(getCategory(token));
    }
  }, [dispatch, token]);

  let cast_id = {
    category_id: selectedCategory,
  };
  useEffect(() => {
    if (token) {
      dispatch(getCast(token, cast_id));
    }
  }, [dispatch, token, selectedCategory]);
  const handleChange = (fieldName, selectOption) => {
    setFilter((prev) => ({ ...prev, [fieldName]: selectOption.value }));
  };
  const handleCategoryChange = (fieldName, selectOption) => {
    setFilter((prev) => ({ ...prev, [fieldName]: selectOption }));
  };

  function convertDate(data) {
    const parts = data.split("-");
    const formattedDate = parts[2] + "-" + parts[1] + "-" + parts[0];
    return formattedDate;
  }

  // Handler for the checkbox change event
  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
    // You can handle any additional logic when the checkbox state changes here
    console.log("Date Filter applied:", event.target.checked);
  };

  if (isLoading) {
    return <Loader />;
  }
  return (
    <>
      <div className="section-body">
        <div className="container-fluid">
          <div className="d-flex justify-content-between align-items-center">
            <div className="header-action">
              <h1 className="page-title">Student Reports</h1>
            </div>
            <ul className="nav nav-tabs page-header-tab">
              <li className="nav-item">
                <a
                  className="nav-link active "
                  data-toggle="tab"
                  href="#Student-report"
                  onClick={() => setNewReport(!newReport)}
                >
                  Admission Report
                </a>
              </li>
              {/* <li className="nav-item">
                <a
                  className="nav-link "
                  data-toggle="tab"
                  href="#Student-new-report"
                  onClick={() => setNewReport(!newReport)}
                >
                  New Admission Report
                </a>
              </li> */}
            </ul>
          </div>
        </div>
      </div>
      <div className="section-body mt-4">
        <div className="container-fluid">
          <div className="tab-pane active" id="Student-report1">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-lg-2 col-md-2 col-sm-6 my-1">
                    <Select
                      options={classList}
                      placeholder="Class"
                      value={Class}
                      onChange={(e) => setClass(e)}
                      isSearchable={true}
                    />
                  </div>
                  <div className="col-lg-2 col-md-2 col-sm-6 my-1">
                    <Select
                      options={sectionList}
                      placeholder="Section"
                      value={Section}
                      onChange={(e) => setSection(e)}
                      isSearchable={true}
                    />
                  </div>
                  <div className="col-lg-2 col-md-2 col-sm-6 my-1">
                    <SelectField
                      options={genderOptions}
                      placeholder="Gender"
                      value={filter["Gender"]}
                      onChange={(selectOpt) =>
                        handleChange("Gender", selectOpt)
                      }
                    />
                  </div>
                  <div className="col-lg-2 col-md-2 col-sm-6 my-1">
                    <SelectField
                      options={bloodGroupList}
                      placeholder="Blood Group"
                      value={filter["Blood Group"]}
                      onChange={(selectOpt) =>
                        handleChange("Blood Group", selectOpt)
                      }
                    />
                  </div>
                  <div className="col-lg-2 col-md-2 col-sm-6 my-1">
                    <SelectField
                      options={categoryOption}
                      name="category"
                      placeholder="Category"
                      value={filter["category"]?.value}
                      onChange={(selectOpt) => {
                        handleCategoryChange("category", selectOpt);
                        setSelectedCategory(selectOpt?.value);
                      }}
                    />
                  </div>
                  <div className="col-lg-2 col-md-2 col-sm-6 my-1">
                    <SelectField
                      options={religionList}
                      placeholder="Religion"
                      value={filter["religion"]}
                      onChange={(selectOpt) =>
                        handleChange("religion", selectOpt)
                      }
                    />
                  </div>
                  <div className="col-md-2">
                    <SelectField
                      options={connessionList}
                      placeholder="Concession"
                      value={filter["concession"]}
                      onChange={(selectOpt) =>
                        handleChange("concession", selectOpt)
                      }
                    />
                  </div>
                </div>

                <div className="col-lg-7 mt-3 mb-2 ">
                  <label className="form-label">Admission Date</label>
                </div>
                <div className="col-md-12">
                  <div className="form-group row">
                    <label className="col-lg-2 col-form-label mr-2">
                      Apply Date Filter
                    </label>

                    <label className="custom-switch">
                      <input
                        type="checkbox"
                        className="custom-switch-input"
                        name="custom-switch-checkbox"
                        id="flexCheckDefault"
                        checked={isChecked} // Bind the state to the checkbox
                        onChange={handleCheckboxChange} // Handle state change on checkbox toggle
                      />
                      <span className="custom-switch-indicator mr-2"></span>
                    </label>
                  </div>
                </div>

                {isChecked && (
                  <div className="row mt-2">
                    <div className="col-md-4 d-flex align-items-center">
                      <label className="col-form-label mr-2 col-md-4">
                        From Date
                      </label>
                      <input
                        type="date"
                        name="from_date"
                        className="form-control"
                        value={filter["from_date"]}
                        disabled={isChecked === false}
                        onChange={(e) => {
                          setFilter((prevFilter) => ({
                            ...prevFilter,
                            from_date: e.target.value,
                          }));
                        }}
                      />
                    </div>
                    <div className="col-md-4 d-flex align-items-center">
                      <label className="col-form-label mr-2 col-md-4">
                        To Date
                      </label>
                      <input
                        type="date"
                        name="to_date"
                        className="form-control"
                        value={filter["to_date"]}
                        disabled={isChecked === false}
                        onChange={(e) => {
                          setFilter((prevFilter) => ({
                            ...prevFilter,
                            to_date: e.target.value,
                          }));
                        }}
                      />
                    </div>
                  </div>
                )}

                <div className="row">
                  <div className="col-lg-12 col-md-8 col-sm-12">
                    <div className="row">
                      <div className="col-lg-4 col-md-4 col-sm-6 my-1"> </div>
                      <div className="col-lg-4 col-md-4 col-sm-6 my-1"> </div>
                      {/* <div className="col-lg-4 col-md-4 col-sm-6 my-1"> 
                       
                        <div
                          className={`input-group  ${!newReport && "d-none"}`}
                        >
                          <label className="col-form-label mx-2">From</label>
                          <input
                            type="date"
                            className="form-control"
                            value={from_date}
                            disabled={newReport && false}
                            onChange={(e) => setFrom_date(e.target.value)}
                          />
                        </div>
                      </div> */}
                      {/* <div className="col-lg-4 col-md-4 col-sm-6 my-1 ">
                        <div
                          className={`input-group  ${!newReport && "d-none"}`}
                        >
                          <label className="col-form-label mx-2">To</label>
                          <input
                            type="date"
                            className="form-control"
                            value={to_date}
                            disabled={newReport && false}
                            onChange={(e) => setTo_date(e.target.value)}
                          />
                        </div>
                      </div> */}
                      <div className="col-lg-4 my-1 text-right">
                        <ClearButton className={"mx-1"} onClick={handleClear} />
                        <ViewButton
                          className={"mx-1"}
                          onClick={() => {
                            newReport
                              ? StudentAllReport("New")
                              : StudentAllReport("All");
                          }}
                        />
                        {Studentsdata?.length > 0 && (
                          <ExportButton id="test-table-xls-button" />
                        )}
                        {/* <ReactHtmlTableToExcel
                    id="test-table-xls-button"
                    className="btn btn-primary mx-1"
                    table="table-to-xls"
                    filename="students_data"
                    sheet="students_data"
                    buttonText="Export to Excel"
                  /> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header">
                <h3 className="card-title">Student List</h3>
              </div>

              <div className="card-body">
                {Studentsdata && Studentsdata.length > 0 ? (
                  <div className="table-responsive">
                    <table
                      className="table table-bordered table-sm table-hover mb-0 text-nowrap text-center"
                      id="table-to-xls"
                    >
                      <thead>
                        <tr>
                          <th>Sr No</th>
                          <th>Registration Date</th>
                          <th>Student Code</th>
                          <th className="text-left">roll no</th>
                          <th className="text-left">Student Name</th>
                          <th>Admission date</th>
                          <th>Gender</th>
                          <th>Blood Group</th>
                          <th>D.O.B.</th>
                          <th>Birth Place</th>
                          <th>Age</th>
                          <th>Category</th>
                          <th>Caste</th>
                          <th>Sub Caste</th>
                          <th>Religion</th>
                          <th>Application No</th>
                          <th>Mobile No</th>
                          <th>Email</th>
                          <th>Address</th>
                          <th>Father Name</th>
                          <th>Father Mobile</th>
                          <th>Father Occupation</th>
                          <th>Mother Name</th>
                          <th>Mother Mobile</th>
                          <th>Mother Occupation</th>
                          <th>Aadhaar No</th>
                          <th>Student Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {Studentsdata?.map(({ std_class, section_data }) => (
                          <>
                            <tr className="table-secondary text-success text-left ">
                              <td colSpan={37}>
                                Class :{" "}
                                <span className="">{`${std_class}`}</span>
                              </td>
                            </tr>

                            {section_data?.map(({ section, student_data }) => (
                              <>
                                <tr className="table-secondary text-success text-left ">
                                  <td colSpan={37}>
                                    Section :{" "}
                                    <span className="">{`${section}`}</span>
                                  </td>
                                </tr>

                                {student_data?.map((list, i) => (
                                  <>
                                    <tr key={list.id}>
                                      <td>{i + 1}.</td>
                                      <td>
                                        {convertDate(list.registration_date)}
                                      </td>
                                      <td>{list.student_code}</td>
                                      <td className="text-left">
                                        {Number(list.roll_no) === 0
                                          ? "N/A"
                                          : list.roll_no}
                                      </td>
                                      <td className="text-left">
                                        {list.std_name}
                                      </td>
                                      <td>{list.admission_date}</td>
                                      <td className="text-left">
                                        {list.gender}
                                      </td>
                                      <td>{list.blood_group}</td>
                                      <td>{convertDate(list.date_of_birth)}</td>
                                      <td className="text-left">
                                        {list.birth_place}
                                      </td>
                                      <td>{list.age || "NA"}</td>
                                      <td>{list.category_name}</td>
                                      <td>{list.caste_name}</td>
                                      <td>{list.sub_caste}</td>
                                      <td>{list.religion}</td>

                                      <td>{list.application_no}</td>
                                      <td>{list.contact_no}</td>
                                      <td>{list.email}</td>
                                      <td>{list.address}</td>
                                      <td>{list.father_name}</td>
                                      <td>{list.f_contact_no}</td>
                                      <td>{list.f_occupation}</td>
                                      <td>{list.mother_name}</td>
                                      <td>{list.m_contact_no}</td>
                                      <td>{list.m_occupation}</td>
                                      <td>{list.adhar_no}</td>
                                      <td>{list.student_status}</td>
                                    </tr>
                                  </>
                                ))}
                              </>
                            ))}
                          </>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <EmptyListMessage
                    message={"Student List Not Available !"}
                    Div="div"
                  />
                )}
              </div>
            </div>
          </div>
          {/* <div className="tab-pane" id="Student-new-report"></div> */}
        </div>
      </div>
    </>
  );
};

export default StudentReports;
