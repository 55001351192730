import {
  GET_FEECOLLECTION_FAILURE,
  GET_FEECOLLECTION_REQUEST,
  GET_FEECOLLECTION_SUCCESS,
  GET_SPEC_FEECOLLECTION_FAILURE,
  GET_SPEC_FEECOLLECTION_REQUEST,
  GET_SPEC_FEECOLLECTION_SUCCESS,
  CLEAR_FEECOLLECTION,
} from "../../Constants/school_user/FeeDiscount";

const initialState = {
  loading: false,
};

export const feeCollectionReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_FEECOLLECTION_REQUEST:
    case GET_SPEC_FEECOLLECTION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_FEECOLLECTION_SUCCESS:
      return {
        ...state,
        loading: false,
        regularfee: action.payload,
      };
    case GET_SPEC_FEECOLLECTION_SUCCESS:
      return {
        ...state,
        loading: false,
        specialfee: action.payload,
      };
    case GET_FEECOLLECTION_FAILURE:
    case GET_SPEC_FEECOLLECTION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
      case CLEAR_FEECOLLECTION:
        return {
          ...state,
          regularfee: null,
          specialfee: null,
        };
  
    default:
      return state;
  }
};
