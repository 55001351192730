import React, { useContext, useEffect, useState } from "react";
import { Container, Row, Card, CardBody, Col, Table } from "reactstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import "flatpickr/dist/themes/material_blue.css";
import { AttendanceContext } from "../../../context/attendanceContext";
import LeftSidebar from "./LeftSidebar";
import HeaderPopup from "./HeaderPopup";
import { useDispatch, useSelector } from "react-redux";
import { Modal, ModalBody } from "reactstrap";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import {
  getClassSection,
  getStudentList,
  saveAttendance,
} from "../../../actions/school_user/Attenadance";
import Loader from "../../Loader/Loader";
import Swal from "sweetalert2";
import { resetState } from "../../../actions/ResetAction/ResetAction";
import CustomScrollbar from "../../CommonComponent/PerfectScrollbar";
import { scrollToTop } from "../../CommonComponent/helper";
import { getAttendanceReport } from "../../../actions/school_user/Attenadance";

const Attendance = () => {
  const token = localStorage.getItem("token");
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [displayContent, setDisplayContent] = useState(true);

  useEffect(() => {
    setIsMobile(window.innerWidth <= 768);
  }, [window.innerWidth]);

  const {
    s_data,
    studentList,
    isLoading,
    data,
    setIsLoading,
    session_id,
    classSelect,
    school_id,
    school_emp_id,
    attendence_date,
    setClassSelect,
    setStudentList,
  } = useContext(AttendanceContext);

  const dispatch = useDispatch();
  const [students, setStudents] = useState([]);

  const [search, setSearch] = useState("");
  const keys = ["student_name", "student_code"];
  const handlesearch = (e) => {
    setSearch(e.target.value);
  };
  useEffect(() => {
    dispatch(resetState());
  }, [dispatch]);
  useEffect(() => {
    setClassSelect("");
  }, []);
  useEffect(() => {
    if (classSelect) {
      setStudents(studentList?.payload);
      if (studentList?.present_list?.length > 0) {
        Swal.fire({
          title: "",
          text: "Attendance is already marked. Do you want to edit it?",
          icon: "info",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          cancelButtonText: "No",
          confirmButtonText: "Yes",
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
          } else {
            setStudents([]);
            setStudentList([]);
          }
        });
      }
    } else {
      setStudents([]);
      setStudentList([]);
    }
  }, [studentList?.payload, classSelect]);
  const handleCheckboxChange = (studentId) => {
    const updatedStudents = students.map((student) => {
      if (student.student_id === studentId) {
        student.is_present = !student.is_present;
      }
      return student;
    });

    setStudents(updatedStudents);
  };
  const makeAddendance = async () => {
    scrollToTop();
    if (!classSelect) {
      Swal.fire("", "Please Select Class ", "info");
      return;
    }
    if (students?.length === 0) {
      Swal.fire("", "Student List Not Found", "info");
      return;
    }

    let student_data = students?.map((s) => {
      return { student_id: s.student_id, is_present: s.is_present, id: s.id };
    });

    let save_data = {
      fk_academic: session_id,
      student_attendance_id: "",
      fk_section: classSelect,
      fk_school: school_id,
      school_emp_id: school_emp_id,
      attendance_date: attendence_date,
      student_data: student_data,
    };

    try {
      setIsLoading(true);
      let response = await dispatch(saveAttendance(token, save_data));
      if (response.status === 200) {
        await Swal.fire({
          text: response.msg,
          icon: "success",
          imageAlt: "success image",
          confirmButtonText: "OK",
        });
        setClassSelect("");
      } else if (response.status === 403) {
        await Swal.fire({
          text: response.msg,
          icon: "error",
          imageAlt: "error image",
          confirmButtonText: "OK",
        });
      }
      // await dispatch(getClassSection(token, s_data));
      // await dispatch(getStudentList(token, data));
      setIsLoading(false);
    } catch (error) {
      console.error("Error sending roll numbers:", error);
    } finally {
      setIsLoading(false);
    }
  };

  ///////////////////////////////////////////////////
  // const token = localStorage.getItem("token");

  const { classListOption, handleclassselect, selectedDate, handleDateChange } =
    useContext(AttendanceContext);
  const { studentreport } = useSelector((state) => state.attendance);
  const [presentmodal, setpresentModal] = useState(false);
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");
  // const dispatch = useDispatch();

  const Ptoggle = () => {
    if (presentmodal) {
      setpresentModal(false);
    } else {
      setpresentModal(true);
    }
  };

  const [loading, setLoading] = useState(false);
  const [AttendanceReport, setAttendanceReport] = useState([]);

  const GenerateAttendanceReport = async () => {
    // if(){

    // }
    setLoading(true);
    try {
      const date = new Date(selectedDate);
      const monthY = String(date.getMonth() + 1).padStart(2, "0");
      const yearY = date.getFullYear();
      const body = {
        school_id: school_id,
        section_id: classSelect,
        year: year || yearY,
        month: month || monthY,
        academic_id: session_id,
      };
      console.log();
      if (!classSelect) return;

      const response = await dispatch(getAttendanceReport(token, body));
      if (response.status === 200) {
        console.log("api hit");
        setLoading(false);
        if (response?.payload?.length === 0) {
          await Swal.fire({
            text: "No Attendance Report Found !",
            icon: "warning",
            imageAlt: "warning image",
          });
        } else {
          setAttendanceReport(response?.payload);
          Ptoggle();
        }
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const String = (str) => {
    let num = str;
    num = str.toString();
    return num;
  };

  const fileName = () => {
    const date = new Date(selectedDate);
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear();

    // let name = "Attendance Report" + " " + classSelect + " ";
    let name = "Attendance Report";
    // + month + ", " + year

    return name;
  };

  const currentMonth = String(new Date().getMonth() + 1).padStart(2, "0");
  const [selectedMonth, setSelectedMonth] = useState(currentMonth.toString());
  const months = [
    { value: "01", label: "January" },
    { value: "02", label: "February" },
    { value: "03", label: "March" },
    { value: "04", label: "April" },
    { value: "05", label: "May" },
    { value: "06", label: "June" },
    { value: "07", label: "July" },
    { value: "08", label: "August" },
    { value: "09", label: "September" },
    { value: "10", label: "October" },
    { value: "11", label: "November" },
    { value: "12", label: "December" },
  ];

  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
    setMonth(event.target.value);
  };

  const years = () => {
    const currentYear = new Date(selectedDate).getFullYear();
    const yearsArray = [];
    for (let i = currentYear - 0; i <= currentYear + 0; i++) {
      yearsArray.push(i.toString());
    }
    return yearsArray[0];
  };
  const [selectedYear, setSelectedYear] = useState(years);

  const handleYearChange = (value) => {
    if (value.length <= 4) {
      setSelectedYear(value);
      setYear(value);
    }
  };
  const handlePresentClick = (arg) => {
    GenerateAttendanceReport();
  };

  //////////////////////////////////////////////////

  return (
    <div className="page-content">
      <Container fluid>
        <Row>
          <Col xl={4}>
            <LeftSidebar />
          </Col>
          <Col xl={8}>
            <Row>
              <HeaderPopup />
            </Row>
            <Card>
              <CardBody>
                <Row>
                  <Col md={11} className="m-auto">
                    <form className="p-0">
                      <div className="input-group ">
                        <input
                          className="form-control bg-light  border"
                          type="search"
                          value={search || ""}
                          onChange={handlesearch}
                          id="search"
                          placeholder="Search by Student code or Name"
                          autoComplete="off"
                        />
                        <span className="input-group-append">
                          <button className="btn ms-n5" type="button">
                            <i className="fa fa-search"></i>
                          </button>
                        </span>
                      </div>
                    </form>
                  </Col>
                </Row>
                <Row>
                  <Col md={12} className="m-auto">
                    <div className="mt-4">
                      {isLoading && <Loader />}
                      {students?.length === 0 ? (
                        <div
                          style={{ height: "310px" }}
                          className="align-center text-center"
                        >
                          {studentList?.msg ? (
                            <h3 className="text-danger mt-5 ">
                              {studentList?.msg} !
                            </h3>
                          ) : (
                            <h3 className="no-list-message mt-5 ">
                              Student List Not Found !
                            </h3>
                          )}
                        </div>
                      ) : (
                        !isLoading && (
                          <CustomScrollbar
                            style={{
                              height: "30rem",
                              paddingRight: "1rem",
                              boxSizing: "content-box",
                            }}
                          >
                            <div className="table-responsive">
                              <Table className="table-sm align-center text-center table-borderless text-nowrap">
                                <thead className="">
                                  <tr className="">
                                  <th className="" scope="col">
                                      <h6>Roll No </h6>
                                    </th>
                                    <th className="text-left" scope="col">
                                      <h6>Student Code </h6>
                                    </th>
                                
                                    <th className="" scope="col">
                                      <h6>Name</h6>
                                    </th>
                                    <th className="" scope="col">
                                      <h6>Present</h6>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {students
                                    ?.filter((stu) =>
                                      keys.some((key) =>
                                        stu[key]
                                          ?.toLowerCase()
                                          .includes(search?.toLowerCase())
                                      )
                                    )
                                    .map((stu, index) => (
                                      <tr key={index}>
                                          <td className="p-0  ">{stu.roll_no}</td>
                                        <td className="p-0 text-left ">
                                          {stu.student_code}
                                        </td>
                                      
                                        <td className="text-left">
                                          <h6 className="text-truncate font-size-13 text-capitalize">
                                            {stu.student_name}
                                          </h6>
                                        </td>
                                        <td className="p-0">
                                          <div className="d-flex align-items-center justify-content-center">
                                            <label className="custom-switch pr-1">
                                              <input
                                                type="checkbox"
                                                className="custom-switch-input"
                                                name="status"
                                                checked={stu.is_present}
                                                onChange={() =>
                                                  handleCheckboxChange(
                                                    stu.student_id
                                                  )
                                                }
                                              />
                                              <span
                                                className="custom-switch-indicator"
                                                style={{
                                                  backgroundColor:
                                                    stu.is_present
                                                      ? "#79AC78"
                                                      : "#F15A59",
                                                }}
                                              ></span>
                                            </label>
                                            {stu.is_present ? (
                                              <span className="">YES</span>
                                            ) : (
                                              <span> NO</span>
                                            )}
                                          </div>
                                        </td>
                                      </tr>
                                    ))}
                                </tbody>
                              </Table>
                            </div>
                          </CustomScrollbar>
                        )
                      )}
                    </div>
                  </Col>
                </Row>
                <div className="row d-flex justify-content-end">
                  {students?.length > 0 ? (
                    <button
                      type="button"
                      className="mt-1 btn px-5"
                      onClick={() => makeAddendance()}
                      style={{
                        backgroundColor: "#42a5f5",
                        color: "#fff",
                      }}
                    >
                      Save
                    </button>
                  ) : (
                    ""
                  )}
                </div>
              </CardBody>
            </Card>

            {isMobile && (
              <Card>
                <CardBody className="p-4">
                  <div className="text-center">
                    <Row className="">
                      <Col xl={12}>
                        <h4 className="card-title mb-3 me-2 text-start">
                          Generate Attendance Report
                        </h4>
                        <Row>
                          <Col>
                            <h4 className="card-title me-4 text-start">
                              Month
                            </h4>
                            <select
                              value={selectedMonth}
                              className="form-control"
                              onChange={handleMonthChange}
                            >
                              <option
                                value=""
                                disabled
                                style={{ fontWeight: "bold" }}
                              >
                                Select Month
                              </option>
                              {months.map((month) => (
                                <option
                                  key={month.value}
                                  value={month.value}
                                  className="text-dark"
                                >
                                  {month.label}
                                </option>
                              ))}
                            </select>
                          </Col>
                          <Col>
                            <h4 className="card-title me-4 text-start">Year</h4>
                            <input
                              className="form-control"
                              value={selectedYear}
                              onChange={(e) => handleYearChange(e.target.value)}
                              type="number"
                            />
                          </Col>
                        </Row>

                        <button
                          onClick={() => handlePresentClick()}
                          type="button"
                          className="btn bg-primary text-white btn-rounded text-center mt-4"
                        >
                          GENERATE {fileName().toUpperCase()}
                        </button>

                        <Modal
                          isOpen={presentmodal}
                          toggle={Ptoggle}
                          // style={{ width: "380px" }}
                          size="xl"
                        >
                          <ReactHTMLTableToExcel
                            id="test-table-xls-button"
                            className="download-table-xls-button btn-lg btn btn-success"
                            color="success"
                            table="table-to-xls"
                            filename={fileName()}
                            sheet="tablexls"
                            buttonText="Print"
                          />
                          <ModalBody>
                            <Card className="shadow-none">
                              <CardBody>
                                <div className="table-responsive">
                                  <Table
                                    className="table table-striped"
                                    id="table-to-xls"
                                  >
                                    <thead>
                                      <tr>
                                        <th className="text-center">Sr.</th>
                                        <th className="text-center">Roll No</th>
                                        <th className="text-center">Name</th>
                                        <th className="text-center">1</th>
                                        <th className="text-center">2</th>
                                        <th className="text-center">3</th>
                                        <th className="text-center">4</th>
                                        <th className="text-center">5</th>
                                        <th className="text-center">6</th>
                                        <th className="text-center">7</th>
                                        <th className="text-center">8</th>
                                        <th className="text-center">9</th>
                                        <th className="text-center">10</th>
                                        <th className="text-center">11</th>
                                        <th className="text-center">12</th>
                                        <th className="text-center">13</th>
                                        <th className="text-center">14</th>
                                        <th className="text-center">15</th>
                                        <th className="text-center">16</th>
                                        <th className="text-center">17</th>
                                        <th className="text-center">18</th>
                                        <th className="text-center">19</th>
                                        <th className="text-center">20</th>
                                        <th className="text-center">21</th>
                                        <th className="text-center">22</th>
                                        <th className="text-center">23</th>
                                        <th className="text-center">24</th>
                                        <th className="text-center">25</th>
                                        <th className="text-center">26</th>
                                        <th className="text-center">27</th>
                                        <th className="text-center">28</th>
                                        <th className="text-center">29</th>
                                        <th className="text-center">30</th>
                                        <th className="text-center">31</th>
                                        <th className="text-center">Present</th>
                                        <th className="text-center">Absent</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {AttendanceReport?.map((stu, index) => {
                                        const value = stu[index + 1];
                                        return (
                                          <tr key={index}>
                                            <th
                                              scope="row"
                                              className="text-center"
                                            >
                                              {index + 1}
                                            </th>
                                            <td className="text-center">
                                              {stu.serial_no}
                                            </td>
                                            <td className="text-center">
                                              {stu.student_name}
                                            </td>
                                            <td className="text-center">
                                              {stu[1]}
                                            </td>
                                            <td className="text-center">
                                              {stu[2]}
                                            </td>
                                            <td className="text-center">
                                              {stu[3]}
                                            </td>
                                            <td className="text-center">
                                              {stu[4]}
                                            </td>
                                            <td className="text-center">
                                              {stu[5]}
                                            </td>
                                            <td className="text-center">
                                              {stu[6]}
                                            </td>
                                            <td className="text-center">
                                              {stu[7]}
                                            </td>
                                            <td className="text-center">
                                              {stu[8]}
                                            </td>
                                            <td className="text-center">
                                              {stu[9]}
                                            </td>
                                            <td className="text-center">
                                              {stu[10]}
                                            </td>
                                            <td className="text-center">
                                              {stu[11]}
                                            </td>
                                            <td className="text-center">
                                              {stu[12]}
                                            </td>
                                            <td className="text-center">
                                              {stu[13]}
                                            </td>
                                            <td className="text-center">
                                              {stu[14]}
                                            </td>
                                            <td className="text-center">
                                              {stu[15]}
                                            </td>
                                            <td className="text-center">
                                              {stu[16]}
                                            </td>
                                            <td className="text-center">
                                              {stu[17]}
                                            </td>
                                            <td className="text-center">
                                              {stu[18]}
                                            </td>
                                            <td className="text-center">
                                              {stu[19]}
                                            </td>
                                            <td className="text-center">
                                              {stu[20]}
                                            </td>
                                            <td className="text-center">
                                              {stu[21]}
                                            </td>
                                            <td className="text-center">
                                              {stu[22]}
                                            </td>
                                            <td className="text-center">
                                              {stu[23]}
                                            </td>
                                            <td className="text-center">
                                              {stu[24]}
                                            </td>
                                            <td className="text-center">
                                              {stu[25]}
                                            </td>
                                            <td className="text-center">
                                              {stu[26]}
                                            </td>
                                            <td className="text-center">
                                              {stu[27]}
                                            </td>
                                            <td className="text-center">
                                              {stu[28]}
                                            </td>
                                            <td className="text-center">
                                              {stu[29]}
                                            </td>
                                            <td className="text-center">
                                              {stu[30]}
                                            </td>
                                            <td className="text-center">
                                              {stu[31]}
                                            </td>
                                            <td className="text-center">
                                              {stu.present}
                                            </td>
                                            <td className="text-center">
                                              {stu.absent}
                                            </td>
                                          </tr>
                                        );
                                      })}
                                    </tbody>
                                  </Table>
                                </div>
                              </CardBody>
                            </Card>
                          </ModalBody>
                        </Modal>
                      </Col>
                    </Row>
                  </div>
                </CardBody>
              </Card>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Attendance;
