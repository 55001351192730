import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useAlert } from "react-alert";
import { getStudentList } from "../../../actions/school_user/StudentRegistration";
import Swal from "sweetalert2";
import { Modal, ModalBody, ModalFooter, Table } from "reactstrap";
import moment from "moment";
import Select from "react-select";
import {
  deleteProspectus,
  saveProspectus,
  updateProspectus,
  viewProspectus,
} from "../../../actions/Admission/ProspectusAction";
import PrintProspectusReceipt from "./PrintProspectusReceipt";
import useSessionRedirect from "../Comman/SessionChange";
import { pay_mode } from "../../../data/optionData";
import {
  CustomModalBody,
  CustomModalHeader,
} from "../../CommonComponent/modelComponent";
import "./styles.css";

const ProspectusReceipt = () => {
  const token = localStorage.getItem("token");
  const { uid } = useParams();
  const [ProspectusId, setProspectusId] = useState(uid);
  const [receiptDate, setreceiptDate] = useState(
    new Date().toISOString().substr(0, 10)
  );

  const school_user = JSON.parse(localStorage.getItem("school_user"));
  const { id, user_type, name, user_detail } = school_user;
  let s_id = user_detail?.fk_school_id;
  const { school_emp_id } = user_detail;

  const session_id = useSessionRedirect();

  const { Prospectus } = useSelector((state) => state.viewProspectus);
  const { payload } = useSelector((state) => state.deleteProspectus);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const alert = useAlert();

  const [PaymentMode, setPaymentMode] = useState();
  const PaymentList = pay_mode?.map((item) => {
    return {
      label: item.label,
      value: item.value,
    };
  });
  function handlePaymentMode(data) {
    setPaymentMode(data);
  }

  const [formData, setformData] = useState({
    prospectus_id: ProspectusId,
    academic_id: Number(session_id),
    school_id: Number(user_detail.fk_school_id),
    student_name: "",
    pros_number: "",
    pros_date: receiptDate,
    contact_no: "",
    amount: "",
    payment_mode: "Cash",
    bank_name: "",
    pay_no: "",
    pay_date: receiptDate,
    remark: "",
    insert_by: school_emp_id,
  });

  const [receiptType, setReceiptType] = useState("New");

  const handleForm = async () => {
    const body = {
      ...formData,
      pros_date: receiptDate,
      pay_date: receiptDate,
    };
    if (receiptType === "New") {
      dispatch(saveProspectus(body, alert));
    } else {
      dispatch(
        updateProspectus({ ...body, prospectus_id: ProspectusId }, alert)
      );
    }
  };

  const getProspectus = () => {
    const get = {
      academic_id: Number(session_id),
      school_id: Number(user_detail.fk_school_id),
      payment_mode: "All",
    };
    dispatch(viewProspectus(get));
  };

  useEffect(() => {
    getProspectus();
  }, [dispatch, session_id, payload]);

  const [searchQuery, setSearchQuery] = useState("");
  const [idQuery, setIdQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [noResultsMessage, setNoResultsMessage] = useState("");
  const [modal, setModal] = useState(false); 

  const toggle = () => {
    setModal(!modal);
    setSearchResults([]);
    setNoResultsMessage("");
    setIdQuery("");
    setSearchQuery("");
  };

  const handleInputChangeSearch = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    console.log(query);
  };
  const handleIdInputChange = (e) => {
    const query = e.target.value;
    setIdQuery(query);
    console.log(query);
  };
  const handleSearch = () => {
    if (searchQuery.trim() === "" && idQuery.trim() === "") {
      Swal.fire({
        text: "Please enter Prospectus number or Student Name",
        icon: "error",
      });

      return;
    }
    const filteredResults = Prospectus?.payload?.filter(
      (item) =>
        item.student_name?.toLowerCase().includes(searchQuery?.toLowerCase()) &&
        item.pros_number?.toString().includes(idQuery)
    );
    setSearchResults(filteredResults);
    setSearchQuery("");
    setIdQuery("");
    if (filteredResults?.length === 0) {
      setNoResultsMessage("No results found.");
    } else {
      setNoResultsMessage("");
    }
  };

  useEffect(() => {
    let body = {
      fk_academic: session_id,
      school_id: s_id,
    };
    if (token) {
      dispatch(getStudentList(token, body));
    }
  }, [dispatch, token, session_id]);

  const handleStudentEdit = (data) => {
    setformData(data);
    setPaymentMode({
      label: data?.payment_mode,
      value: data?.payment_mode,
    });
    setProspectusId(data?.id);
    setModal(!modal);
    setReceiptType("Edit");
  };

  const handleDelete = () => {
    const body = {
      prospectus_id: Number(ProspectusId),
    };
    dispatch(deleteProspectus(body));
    handleClear();
  };

  const handleClear = () => {
    setProspectusId("");
    setformData({
      prospectus_id: "",
      academic_id: Number(session_id),
      school_id: Number(user_detail.fk_school_id),
      student_name: "",
      pros_number: "",
      pros_date: receiptDate,
      contact_no: "",
      amount: "",
      payment_mode: "Cash",
      bank_name: "",
      pay_no: "",
      pay_date: receiptDate,
      remark: "",
    });
    setReceiptType("New");
    setbtnClick(true);
    setPaymentMode(PaymentList[0]);
    getProspectus();
  };

  const [btnClick, setbtnClick] = useState(true);
  useEffect(() => {
    if (
      formData.amount &&
      formData.pros_date &&
      formData.payment_mode &&
      // formData.contact_no &&
      formData.student_name &&
      formData.pros_number
    ) {
      setbtnClick(false);
    }
  });

  function convertDate(data) {
    const parsedDate = moment(data, "YYYY-MM-DD");
    const formattedDate = parsedDate.format("DD-MM-YYYY");
    return formattedDate;
  }

  return (
    <div className="section-body">
      <div className="container-fluid">
        <div className="d-flex justify-content-between align-items-center">
          <div className="header-action">
            <h1 className="page-title">Prospectus Receipt</h1>
            {/* <ol className="breadcrumb page-breadcrumb">
              <li className="breadcrumb-item active" aria-current="page">
                Prospectus Fees 
              </li>
            </ol> */}
          </div>

          <div className="input-group-append">
            <PrintProspectusReceipt
              receiptDate={receiptDate}
              formData={formData}
              schoolUser={school_user}
              handleForm={handleForm}
              receiptType={receiptType}
              // classSec={
              //   studentName?.payload?.std_class +
              //   " " +
              //   studentName?.payload?.section
              // }
              btnClick={btnClick}
              convertDate={convertDate}
              navigate={navigate}
              handleClear={handleClear}
              handleDelete={handleDelete}
              alert={alert}
            />
            <button
              className="btn btn-outline-secondary"
              type="button"
              onClick={toggle}
            >
              <i className="fa fa-search"> </i> Search
            </button>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-lg-8 col-md-12 col-sm-12">
                <div className="row">
                  <div className="col-lg-6   col-sm-12">
                    <label className="form-label">
                      Prospectus No <span className="text-danger">*</span>
                    </label>
                    <div className="input-group">
                      <input
                        type="number"
                        className="form-control"
                        placeholder="Prospectus No"
                        value={formData.pros_number}
                        onChange={(event) =>
                          setformData((prevState) => ({
                            ...prevState,
                            pros_number: event.target.value,
                          }))
                        }
                      />
                    </div>
                  </div>
                  <div className="col-lg-6  col-sm-12">
                    <label className="form-label ">
                      Issue Date <span className="text-danger">*</span>
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      value={formData.pros_date || receiptDate}
                      // onChange={(e) => setreceiptDate(e.target.value)}
                      onChange={(event) => {
                        setreceiptDate(event.target.value);
                        setformData((prevState) => ({
                          ...prevState,
                          pros_date: event.target.value,
                        }));
                      }}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-9   col-sm-12">
                    <label className="form-label">
                      Student Name <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Student Name"
                      value={formData.student_name}
                      onChange={(event) =>
                        setformData((prevState) => ({
                          ...prevState,
                          student_name: event.target.value,
                        }))
                      }
                    />
                  </div>
                </div>
                <div class="row">
                  <div className="col-lg-4  col-sm-12 my-1">
                    <label className="form-label ">Mobile No</label>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Mobile No"
                      value={formData.contact_no}
                      onChange={(event) => {
                        if (event.target.value.length <= 10) {
                          setformData((prevState) => ({
                            ...prevState,
                            contact_no: event.target.value,
                          }));
                        }
                      }}
                    />
                  </div>
                </div>
                {formData.contact_no.length > 10 && (
                  <p className="text-danger">Please enter 10 digits no.</p>
                )}
              </div>
              <div className="col-lg-4 col-md-12 col-sm-12">
                <div className="card">
                  <div className="card-body">
                    <h6 className="page-title">Prospectus Receipt Details</h6>
                    <div className="d-flex py-1">
                      <label className=" givenWigth m-auto">
                        Payment Mode <span className="text-danger">*</span>
                      </label>
                      <Select
                        options={PaymentList}
                        placeholder="Payment Mode"
                        value={PaymentMode}
                        defaultValue={PaymentList[0]}
                        onChange={(event) => {
                          handlePaymentMode(event);
                          setformData((prevState) => ({
                            ...prevState,
                            payment_mode: event.value,
                          }));
                        }}
                        isSearchable={true}
                        className="form-control m-0 p-0"
                      />
                    </div>
                    {formData.payment_mode === "Cash" ? null : (
                      <>
                        {formData.payment_mode === "UPI" ? null : (
                          <div className="d-flex py-1">
                            <label className="givenWigth m-auto">Bank Name</label>
                            <input
                              disabled={
                                PaymentMode?.value === "Cash" ? true : false
                              }
                              className="form-control"
                              type="text"
                              placeholder="Bank Name"
                              value={formData.bank_name}
                              onChange={(event) =>
                                setformData((prevState) => ({
                                  ...prevState,
                                  bank_name: event.target.value,
                                }))
                              }
                            />
                          </div>
                        )}
                        <div className="d-flex py-1">
                          <label className="givenWigth m-auto">
                            {formData.payment_mode === "Bank"
                              ? "Account"
                              : formData.payment_mode}{" "}
                            Number
                          </label>
                          <input
                            disabled={
                              PaymentMode?.value === "Cash" ? true : false
                            }
                            className="form-control"
                            type="number"
                            placeholder={
                              formData.payment_mode === "Bank"
                                ? "Account Number"
                                : formData.payment_mode + " Number"
                            }
                            value={formData.pay_no}
                            onChange={(event) =>
                              setformData((prevState) => ({
                                ...prevState,
                                pay_no: event.target.value,
                              }))
                            }
                          />
                        </div>
                        <div className="d-flex py-1">
                          <label className="givenWigth m-auto">
                            Payment Date <span className="text-danger">*</span>
                          </label>
                          <input
                            disabled={
                              PaymentMode?.value === "Cash" ? true : false
                            }
                            className="form-control"
                            type="date"
                            placeholder="Payment Date"
                            value={formData.pay_date}
                            onChange={(event) =>
                              setformData((prevState) => ({
                                ...prevState,
                                pay_date: event.target.value,
                              }))
                            }
                          />
                        </div>
                      </>
                    )}
                    <div className="d-flex py-1">
                      <label className="givenWigth m-auto">
                        Total Amount <span className="text-danger">*</span>
                      </label>
                      <input
                        className="form-control"
                        type="number"
                        placeholder="Total Amount"
                        value={formData.amount}
                        onChange={(event) =>
                          setformData((prevState) => ({
                            ...prevState,
                            amount: event.target.value, 
                          }))
                        }
                      />
                    </div>

                    <div className="d-flex py-1">
                      <label className="givenWigth m-auto">Remark</label>
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Remark"
                        value={formData.remark}
                        onChange={(event) =>
                          setformData((prevState) => ({
                            ...prevState,
                            remark: event.target.value,
                          }))
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal isOpen={modal} toggle={toggle} fullscreen className="mobel_box" backdrop="static" keyboard={false}>
        <CustomModalHeader toggle={toggle}>
          <div className="row">
            <div className="col-lg-5 col-md-5 col-sm-5 my-1">
              <input
                name="code"
                type="number"
                className="form-control"
                placeholder="Prospectus No"
                value={idQuery}
                onChange={handleIdInputChange}
                autoComplete="off"
              />
            </div>
            <div className="col-lg-5 col-md-5 col-sm-5 my-1 ">
              <input
                type="text"
                className="form-control"
                name="name"
                placeholder="Student Name"
                value={searchQuery}
                onChange={handleInputChangeSearch}
                autoComplete="off"
              />
            </div>
            <div className="col-lg-2 col-md-2 col-sm-2 my-1 text-right">
              <button
                type="submit"
                className="btn btn-primary"
                onClick={handleSearch}
              >
                Search
              </button>
            </div>
          </div>
        </CustomModalHeader>
        <CustomModalBody noResultsMessage={noResultsMessage}>
          <thead>
            <tr className="text-center">
              <th>Issue Date</th>
              <th>Prospectus No.</th>
              <th>Student Name</th>
              <th>Mobile No</th>
              <th>Amount</th>
              <th>Remark</th>
            </tr>
          </thead>
          <tbody>
            {searchResults?.map((stu, index) => (
              <tr
                key={index}
                className="text-center"
                onClick={() => handleStudentEdit(stu)}
              >
                <td>{convertDate(stu.pros_date)}</td>
                <td>{stu.pros_number}</td>
                <td className="text-left">{stu.student_name}</td>
                <td>{stu.contact_no}</td>
                <td>{stu.amount}</td>
                <td>{stu.remark}</td>

                {/* <td className="text-center ">
                      <NavLink
                        className="text-muted"
                        // to={`/school/editstaffmaster/${school.id}`}
                      >
                        <i className="fa fa-edit"></i>
                      </NavLink>
                    </td> */}
              </tr>
            ))}
          </tbody>
        </CustomModalBody>
      </Modal>
    </div>
  );
};

export default ProspectusReceipt;
