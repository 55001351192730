import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getRegularFeeCollection,
  getSpecialFeeCollection,
} from "../../../actions/school_user/FeeCollection";
import useSessionRedirect from "../Comman/SessionChange";
import CollectionTable from "./CollectionTable";
import useLoading from "../../CommonComponent/useLoading";
import Loader from "../../Loader/Loader";
import Swal from "sweetalert2";
import { CLEAR_FEECOLLECTION } from "../../../Constants/school_user/FeeDiscount";


const FeeCollection = () => {
  const token = localStorage.getItem("token");
  const school_user = JSON.parse(localStorage.getItem("school_user"));
  const { id, user_type, name, user_detail } = school_user;
  let school_id = user_detail?.fk_school_id;
  const { classsectionList } = useSelector((state) => state.attendance);
  const session_id = useSessionRedirect();
  const { regularfee, specialfee } = useSelector(
    (state) => state.feeCollection
  );
  const dispatch = useDispatch();
  const currentDate = new Date();
  const oneMonthAgo = new Date();
  oneMonthAgo.setMonth(currentDate.getMonth() - 1);
  const [selectedId, setSelectedId] = useState([]);
  const [from_date1, setFrom_date1] = useState(oneMonthAgo.toISOString().substr(0, 10));
  const [to_date1, setTo_date1] = useState( new Date().toISOString().substr(0, 10));
  const [AdmissionStatus1, setAdmissionStatus1] = useState(['Admitted']);
  const [selectedFeeheads, setSelectedFeeHeads] = useState([]);
  const [from_date2, setFrom_date2] = useState(
    new Date().toISOString().substr(0, 10)
  );
  const [to_date2, setTo_date2] = useState( new Date().toISOString().substr(0, 10));
  const [AdmissionStatus2, setAdmissionStatus2] = useState(['Admitted']);
  const { isLoading, startLoading, stopLoading } = useLoading(); 
    const data1 = classsectionList?.payload?.map((c) => ({
    value: c.id,
    label: `${c.class_name} - ${c.section}`,
  }));
  console.log(selectedId,"././././././3123123123./././.")


  const handleClear = () => {{
    setSelectedFeeHeads([]);
    setFrom_date1(oneMonthAgo.toISOString().substr(0, 10));
    setTo_date1(new Date().toISOString().substr(0, 10));
    setAdmissionStatus1(['Admitted']);
    setAdmissionStatus2(['Admitted'])
    setSelectedId([]);
    dispatch({ type: CLEAR_FEECOLLECTION });

  }};




  const AdmissionStatusType = [
    { value: "Admitted", label: "Admitted" },
    { value: "Cancelled", label: "Cancelled" },
    { value: "Leaved", label: "Leaved" },
    { value: "Promoted", label: "Promoted" },
  ];

  const GetRegularFeeCollection = () => {
    if(AdmissionStatus1.length == 0){
      Swal.fire({
        text: "Please Select Admission Status",
        icon: "warning",
        title: "ALERT",
        allowOutsideClick: false,
      })
      return
    }
    const body = {
      academic_id: session_id,
      school_id: school_id,
      from_date: from_date1,
      to_date: to_date1,
      section_id :JSON.stringify(selectedId),
      admission_status: JSON.stringify(AdmissionStatus1),
      heads_name: selectedFeeheads,
    };
    try {
      dispatch(getRegularFeeCollection(body));
    } catch (error) {
      console.error(error);
    }
  };

if(isLoading) return <Loader />
  // useEffect(() => {
  //   GetRegularFeeCollection();
  // }, [from_date1, to_date1, session_id, AdmissionStatus1]);
  const GetSpecialFeeCollection = () => {
    if(AdmissionStatus1.length == 0){
      Swal.fire({
        text: "Please Select Admission Status",
        icon: "warning",
        title: "ALERT",
        allowOutsideClick: false,
      })
      return
    }
    const body = {
      academic_id: session_id,
      school_id: school_id,
      from_date: from_date2,
      to_date: to_date2,
      admission_status: JSON.stringify(AdmissionStatus2),
    };
    try {
      dispatch(getSpecialFeeCollection(body));
    } catch (error) {
      console.error(error);
    }
  };
  // useEffect(() => {
  //   GetSpecialFeeCollection();
  // }, [from_date2, to_date2, session_id, AdmissionStatus2]); 



  return (
    <div className="container-fluid">
      <div className="d-flex justify-content-between align-items-center mx-1">
        <div className="header-action">
          <h1 className="page-title">Fee Collection</h1>   
        </div>
        <ul className="nav nav-tabs page-header-tab">
          <li className="nav-item">
            <a
              className="nav-link active"
              data-toggle="tab"
              href="#Regular"
              // onClick={handleClear}
            >
              Regular Fee 
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link"
              data-toggle="tab"
              href="#Special"
              // onClick={handleClear}
            >
              Special Fee 
            </a>
          </li>
        </ul>
      </div>
      <div className="tab-content">
        <div className="tab-pane active mt-3" id="Regular">
          <CollectionTable
            feeCollectionData={regularfee}
            from_date={from_date1}
            setFrom_date={setFrom_date1}
            setTo_date={setTo_date1}
            to_date={to_date1}
            AdmissionStatus={AdmissionStatus1}
            setAdmissionStatus={setAdmissionStatus1}
            optionList={AdmissionStatusType}
            placeholder={"Select admission status"}
            FeeTable={"regularfee"}
            GetRegularFeeCollection={GetRegularFeeCollection}
            setSelectedFeeHeads={setSelectedFeeHeads}
            selectedFeeheads={selectedFeeheads }
            handleClear={handleClear}
            setSelectedId={setSelectedId}
            selectedId={selectedId}
            data1={data1}
          />
        </div>
        <div className="tab-pane mt-3" id="Special">
          <CollectionTable
            feeCollectionData={specialfee}
            from_date={from_date2}
            setFrom_date={setFrom_date2}
            setTo_date={setTo_date2}
            to_date={to_date2}
            AdmissionStatus={AdmissionStatus2}
            setAdmissionStatus={setAdmissionStatus2}
            optionList={AdmissionStatusType}
            placeholder={"Select admission status"}
            FeeTable={"specialfee"}
            GetRegularFeeCollection={GetSpecialFeeCollection}
            setSelectedFeeHeads={setSelectedFeeHeads}
            handleClear={handleClear}
          />
        </div>
      </div>
    </div>
  );
};

export default FeeCollection;
