import React, { useCallback, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AiOutlineFileSearch } from "react-icons/ai";
import { SessionContext } from "../../../context/sessionContext";
import { getStudentList, getStudentListForDiscount } from "../../../actions/school_user/StudentRegistration";
import {
  CustomModal,
  CustomModalBody,
  CustomModalHeader,
} from "../../CommonComponent/modelComponent";
import { convertDate } from "../../CommonComponent/DateFormatUtil";

const StudentList2 = (props) => {
  const { FeesDetails, setstudent_uid, setDisabledAll  ,setStu_adm_id ,studata ,student_uid} = props;
  const token = localStorage.getItem("token");
  const school_user = JSON.parse(localStorage.getItem("school_user"));
  const [isId ,setIsId] = useState()

  let studentAdmId = studata?.payload?.stu_adm_id;
  useEffect(()=>{
    setIsId(studentAdmId)
  },[studata])
  
  const { id, user_type, name, user_detail } = school_user;
  let s_id = user_detail?.fk_school_id;
  const { Session } = useContext(SessionContext);
  let session_id = Session || sessionStorage.getItem("SessionId");
  const { studentdataforDiscount }  = useSelector((state) => state.discountstudentList);
  const [CurrentPage, setCurrentPage] = useState(1);
  const [Page, setPage] = useState(1);
  const resultPerPage = 10;
  const [searchQuery, setSearchQuery] = useState("");
  const [idQuery, setIdQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);

// const { studentdata } = useSelector((state) => state.studentRegistration);
  const [noResultsMessage, setNoResultsMessage] = useState("");
  const [modal, setModal] = useState(false);
  const dispatch = useDispatch();
  const toggle = () => {
    setSearchResults([]);
    setNoResultsMessage("");
    setTimeout(() => setModal(!modal), 0);
  };

  const handleInputChangeSearch = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
  };
  const handleIdInputChange = (e) => {
    const query = e.target.value;
    setIdQuery(query);
  };
  const handleSearch = () => {
    const filteredResults = studentdataforDiscount?.payload?.filter(
      (item) =>
        item.std_name?.toLowerCase().includes(searchQuery?.toLowerCase()) && 
        item.student_code?.toString().includes(idQuery)
    );
    setSearchResults(filteredResults);
    setSearchQuery("");
    setIdQuery("");
    if (filteredResults?.length === 0) {
      setNoResultsMessage("No results found.");
    } else {
      setNoResultsMessage("");
    }
  };
  const dispatchGetStudentList = useCallback(
    (token, body) => {
      return dispatch(getStudentListForDiscount(token, body));
    },
    [dispatch]
  );
  useEffect(() => {
    let body = {
      fk_academic: session_id,
      school_id: s_id,
      student_name: searchQuery,
      student_code: idQuery,
      page: CurrentPage,
      status_type: "Admitted",
      filter_type : "discount"
    };
    if (token) {
      dispatchGetStudentList(token, body).then((res) =>
        setPage(res.totalRecordCount)
      );
    }
  }, [
    dispatchGetStudentList,
    token,
    session_id,
    CurrentPage,
    idQuery,
    searchQuery,
    student_uid
  ]);

  useEffect(() => {
    setSearchResults(studentdataforDiscount?.payload);
  }, [studentdataforDiscount]);

  const handleStudentEdit = (data) => {
    let isIds = data?.id
    setstudent_uid(data?.student_code);
    setStu_adm_id(isId)
    setDisabledAll(true)
    setTimeout(() => FeesDetails(isIds), 0);
    setTimeout(() => setModal(!modal), 0);
  };

  return (
    <>
      {/* <span className="" type="button" onClick={toggle}>
        <AiOutlineFileSearch style={{ fontSize: "1.5rem" }} />
      </span> */}
      <button class="btn btn-outline-secondary mx-1 my-1" type="button" onClick={toggle}><i class="fa fa-search"> </i> Search</button>
      <CustomModal
        modal={modal}
        toggle={toggle}
        activePage={CurrentPage}
        itemsCountPerPage={resultPerPage}
        totalItemsCount={Page}
        onChange={(e) => setCurrentPage(e)}
      >
        <CustomModalHeader toggle={toggle}>
          <div className=" row">
            <div className="col-lg-5 col-md-5 col-sm-5 my-1">
              <input
                name="code"
                type="number"
                className="form-control"
                placeholder="Student code"
                value={idQuery}
                onChange={handleIdInputChange}
                autoComplete="off"
              />
            </div>
            <div className="col-lg-5 col-md-5 col-sm-5 my-1 ">
              <input
                type="text"
                className="form-control"
                name="name"
                placeholder="Student Name"
                value={searchQuery}
                onChange={handleInputChangeSearch}
                autoComplete="off"
              />
            </div>
            <div className="col-lg-2 col-md-2 col-sm-2 my-1 text-right">
              <button
                type="submit"
                className="btn btn-primary"
                onClick={handleSearch}
              >
                Search
              </button>
            </div>
          </div>
        </CustomModalHeader>
        <CustomModalBody noResultsMessage={noResultsMessage}>
          <thead>
            <tr className="text-center text-nowrap">
              <th>Registration Date</th>
              <th>Stud Code</th>
              <th>Student Name</th>
              <th>class section</th>
              <th>Gender</th>
              <th>Student Status</th>
              <th>Academic Session</th>
            </tr>
          </thead>
          <tbody>
            {searchResults?.map((stu, index) => (
              <tr
                key={index}
                className="text-center"
                onClick={() => handleStudentEdit(stu)}
                style={{ cursor: "pointer" }}
              >
                <td>{convertDate(stu.registration_date)}</td>
                <td>{stu.student_code}</td>
                <td className="text-left">{stu.std_name}</td>
                <td>{stu.class_section}</td>
                <td>{stu.gender}</td>
             
                
                
               
                <td>{stu.student_status}</td>
            
                <td>{stu?.academic_year}</td>
              </tr>
            ))}
          </tbody>
        </CustomModalBody>
      </CustomModal>
    </>
  );
};

export default StudentList2;
