import React, { useState } from 'react';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import { Modal, ModalBody, ModalFooter, Button } from 'reactstrap';

const MapModal = ({ show, handleClose, lat, lng }) => {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: 'AIzaSyA9w97EGCFVrTi0OEkK4N5T30nyEpP7IBM' // Replace with your Google Maps API key
  });

  const containerStyle = {
    width: '100%',
    height: '400px'
  };

  const center = {
    lat: lat || 0,
    lng: lng || 0
  };

  return (
    <Modal isOpen={show} toggle={handleClose} fade centered size="lg" backdrop="static" >
      <ModalBody>
        {isLoaded ? (
          <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={14}>
            <Marker
              position={center}
              icon={{
                url: 'https://img.icons8.com/emoji/48/bus-emoji.png', // URL for the bus icon
                scaledSize: new window.google.maps.Size(40, 40)
              }}
            />
          </GoogleMap>
        ) : (
          <div>Loading...</div>
        )}
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={handleClose}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default MapModal;
