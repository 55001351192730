import axios from "axios";
import { BASE_URL } from "../../Config/BaseUrl";
const token = localStorage.getItem("token");
export const feeDiscountAPI = {
    getRegularFees: async (data) => {
    var axiosConfig = {
      method: "post",
      url: `${BASE_URL}get_student_fees_details/`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data,
    };
    return await axios(axiosConfig);
  },
  getStudentDiscountApi: async (data) => {
    var axiosConfig = {
      method: "post",
      url: `${BASE_URL}get_student_discount_api/`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data,
    };
    return await axios(axiosConfig);
  },

  getStudentDiscountApi: async (data) => {
    var axiosConfig = {
      method: "post",
      url: `${BASE_URL}get_student_discount_api/`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data,
    };
    return await axios(axiosConfig);
  },
    getDiscountReport: async (data) => {
    var axiosConfig = {
      method: "post",
      url: `${BASE_URL}filter_discount_reason/`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data,
    };
    return await axios(axiosConfig);
  },
  getFeesHeadsList: async (data) => {
    var axiosConfig = {
      method: "post",
      url: `${BASE_URL}get_fees_heads_list/`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data,
    };
    return await axios(axiosConfig);
  },

  deleteDiscountApi: async (data) => {
    var axiosConfig = {
      method: "post",
      url: `${BASE_URL}delete_discount_api/`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data,
    };
    return await axios(axiosConfig);
  },
  getFeeOutstandingReport: async (data) => {
    var axiosConfig = {
      method: "post",
      url: `${BASE_URL}get_fee_outstanding_report/`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data,
    };
    return await axios(axiosConfig);
  },
};
