import React, { useContext, useEffect, useState } from "react";
import Select from "react-select";
import RegisterStudentList from "../../Comman/RegisterStudentList";
import ErrorDisplay from "../../../CommonComponent/ErrorDisplay";
import ValidationComponent from "../../Comman/Validation";
import StudentSessionWiseList from "../../Comman/StudentListSessionWise";
import { useDispatch, useSelector } from "react-redux";
import AddmittedStudentList from "../../Comman/AddmittedStudentList";
import useSessionRedirect from "../../Comman/SessionChange";
import {
  getRegularFees,
  getStudentName,
} from "../../../../actions/Admission/AdmissionAction";
import { AttendanceContext } from "../../../../context/attendanceContext";
import { getMasterData } from "../../../../actions/super-user/MasterData";
import Loader from "../../../Loader/Loader";
import {
  getFeeList,
  getFeeListStructure,
} from "../../../../actions/super-user/SchoolRegistration";
import { ChangeSectionApi } from "../../../../api/ChangeSection/ChangeSection";

const ChangeSection = () => {
  const dispatch = useDispatch();
  const token = localStorage.getItem("token");
  const [student_uid, setstudent_uid] = useState("");
  const [classSelect, setClassSelect] = useState(null);
  const [newClass, setNewClass] = useState(null);
  const { masterData } = useSelector((state) => state.masterData);
  const session_id = useSessionRedirect();
  const school_user = JSON.parse(localStorage.getItem("school_user"));
  const { user_type, id, name, user_detail } = school_user;
  const [selectconnession, setSelectconnession] = useState(null);
  const [newSelectconnession, setNewSelectconnession] = useState(null);
  const [getConcessionList, setGetConcessionList] = useState([]);
  const [selectedStudent, setSelectedStudent] = useState([]);
  const [admissionDate, setAdmissionDate] = useState("");
  const [std_name, setStd_name] = useState("");
  const [loading, setLoading] = useState(false);
  const [stu_id, setStu_Id] = useState("");
  const { regularFees } = useSelector((state) => state.getRegularFees);
  const [tableData1, setTableData1] = useState([]);

  const { feeStructure } = useSelector((state) => state.schoolFee);
  const [balanceAmount, setbalanceAmount] = useState("");
  const [paidAmount, setPaidAmount] = useState("");
  console.log(paidAmount, ">>>>>>>>>>>><<<<<<<<<<<<<>>>>>>>>>><<<<<<<<<<<<", regularFees, ">>>>>>>>>>>><<<<<<<<<<<<<>>>>>>>>>><<<<<<<<<<<<")
  const [totalAmount, setTotalAmount] = useState("");
  const [PrevAddmissionId, setAddmissionId] = useState("");
  const { school_emp_id, fk_school_id } = user_detail;
  const [remark, setRemark] = useState("");
  const { validationError, validateControl } = ValidationComponent({
    data: { student_uid, classSelect, selectconnession },
    requiredFields: ["student_uid", "classSelect", "selectconnession"],
  });
  const { classListOption } = useContext(AttendanceContext);
  const { studentName, errorCode, message } = useSelector(
    (state) => state.studentName
  );
  let feeId = {
    school_id: Number(user_detail.fk_school_id),
  };
  const [feeTable, setFeesTable] = useState([]);

  let handleclassselect = (e) => {
    setClassSelect(e);
  };

  let handleNewclassselect = (e) => {
    setNewClass(e);
  };

  let con_id = newSelectconnession?.value;

  let fdata = {
    fk_section: newClass?.value || "",
    fk_concession: con_id,
    fk_academic_session: session_id,
    school_id: Number(user_detail?.fk_school_id),
  };

  useEffect(() => {
    if ((token && newClass?.value, newSelectconnession?.value)) {
      dispatch(getFeeListStructure(token, fdata));
    }
  }, [token, session_id, newClass, newSelectconnession]);

  function handleSelectconnession(data) {
    setSelectconnession(data);
  }

  function handleNewSelectconnession(data) {
    setNewSelectconnession(data);
  }
 
  useEffect(() => {
    if (token) {
      dispatch(getMasterData(token));
    }
  }, [dispatch, token, session_id]);

  useEffect(() => {
    setGetConcessionList(
      masterData?.payload?.filter((obj) => obj.name === "Concession")
    );
  }, [masterData, session_id]);

  const FeesDetails = () => {
    if (stu_id === undefined || "") {
      alert.error("Please enter Student Code");
    } else {
      try {
        let body = {
          fk_academic: session_id,
          student_id: stu_id,
          school_id: Number(user_detail?.fk_school_id),
          call_from: "Fees Receipt",
        };
        dispatch(getRegularFees(body));
        // setFeesTable(regularFees?.payload);
      } catch (error) {
        console.error(error);
        setFeesTable([]);
      }
    }
  };
  useEffect(() => {
    if (newClass && newSelectconnession) {
      console.error("i am inside");
      dispatch(getFeeList(token, feeId));
    }
  }, [dispatch, token, session_id, student_uid, newSelectconnession, newClass]);

  useEffect(() => {
    if (stu_id) {
      FeesDetails();
    } else {
      setFeesTable([]);
    }
  }, [session_id, studentName, student_uid, stu_id]);
  function getName() {
    setLoading(true);
    try {
      const body = {
        fk_academic: session_id,
        student_code: student_uid,
        school_id: Number(user_detail.fk_school_id),
        for_type: "Admitted",
      };
      dispatch(getStudentName(body, handleClear));
    } catch {
    } finally {
      setLoading(false);
    }
  }
  const handleInputChange1 = (index, field, value) => {
    const newData = [...tableData1];
    newData[index][field] = value;
    setTableData1(newData);
  };

  useEffect(() => {
    getName();
  }, [student_uid]);
  const connessionList = getConcessionList?.map((cl) => {
    return {
      label: cl.value,
      value: cl.id,
    };
  });

  useEffect(() => {
    if (student_uid && newSelectconnession && newClass) {
      setTableData1(feeStructure?.payload);
    }
  }, [feeStructure, session_id, student_uid, newSelectconnession, newClass]);

  const installments = [];
  const installment_amount = [];
  const discount = [];
  const total_amount = [];
  const Sum_total_amount = () => {
    const sum = total_amount.reduce((acc, current) => acc + current, 0);
    return sum;
  };
  const handleCheckboxChange1 = (index, check) => {
    const newData = [...tableData1];
    newData[index].checked = !newData[index].checked;
    setTableData1(newData);
    handleStatusChange1(index, check);
  };

  const handleStatusChange1 = (index, checkboxType) => {
    const newData = [...tableData1];
    if (checkboxType === true) {
      newData[index].checked = true;
    } else {
      newData[index].checked = false;
    }
    setTableData1(newData);
  };

  useEffect(() => {
    if (student_uid) {
      setAdmissionDate(studentName?.payload?.admission_data?.admission_date);
      setClassSelect(studentName?.payload?.section_data);
      setSelectconnession(studentName?.payload?.consession_data);
      setStd_name(studentName?.payload?.name);
      setStu_Id(studentName?.payload?.id);
      setbalanceAmount(regularFees?.student_balance_fee);
      setPaidAmount(regularFees?.student_total_paid_fee);
      setTotalAmount(regularFees?.student_total_fees);
      setAddmissionId(regularFees?.student_adm_id);
    }
  }, [studentName, regularFees, student_uid]);

  const handleClear = () => {
    setSelectconnession(null);
    setClassSelect(null);
    setAdmissionDate("");
    setSelectedStudent([]);
    setStd_name("");
    setstudent_uid("");
    setbalanceAmount("");
    setPaidAmount("");
    setTotalAmount("");
    setNewClass(null);
    setNewSelectconnession(null);
    setTableData1([]);
    setAddmissionId("");
    setRemark("")
  };

  const HandelSectionChange = async () => {
    const isValid = await validateControl();
    if (!isValid) return;
  
    const dataArray = tableData1?.filter((row) => row.checked);
    const fees_data = dataArray?.map(
      ({
        checked,
        fees_head_name,
        fees_head_code,
        admission_type,
        id,
        ...rest
      }) => rest
    );
  
    if (fees_data?.length === 0) {
      alert.error("Select Fee Heads");
      return; // Stop further execution if no fee heads are selected
    }
  
    let hasError = false;
    fees_data.forEach(
      ({
        installments,
        total_amount,
        student_fees_id,
        installment_amount,
      }) => {
        if (installments === "" || installments === 0) {
          alert.error("Installments empty!");
          hasError = true; // Flag error to stop execution
        } else if (installment_amount === "") {
          alert.error("Amount empty!");
          hasError = true; // Flag error to stop execution
        }
      }
    );
  
    if (hasError) return; // Stop if there are validation errors in installments or amounts
  
    const body = {
      fk_academic: session_id,
      student_id: studentName?.payload.id,
      section_id: newClass?.value,
      fk_concession: con_id,
      admission_date: admissionDate,
      student_code: studentName?.payload.student_code,
      fees_data,
      insert_by: school_emp_id,
      remark: remark,
      previous_adm_id: PrevAddmissionId,
    };
  
    Swal.fire({
      title: "CONFIRMATION",
      text: "Do you want to Save Record?",
      icon: "question",
      showDenyButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#f46a6a",
      confirmButtonText: "Yes",
      denyButtonText: `No`,
      allowOutsideClick: false,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await ChangeSectionApi.changeStudentSection(body); // Await the API call
          const responseData = response?.data;
  
          if (responseData?.status === 200) {
            Swal.fire({
              text: responseData.msg,
              icon: "success",
            });
            handleClear(); // Clear data after successful operation
          } else {
            Swal.fire({
              text: responseData?.msg || "An error occurred",
              icon: "error",
              allowOutsideClick: false,
            });
          }
        } catch (error) {
          console.error("API Error:", error); // Log the actual error
          Swal.fire({
            text: "An unexpected error occurred. Please try again later.",
            icon: "error",
            allowOutsideClick: false,
          });
        }
      }
    });
  };
  

  if (loading) return <Loader />;
  return (
    <div className="section-body mt-4">
      <div className="container-fluid">
        <div className="header-action d-flex justify-content-between mb-2">
          <h1 className="page-title">Student Change Section</h1>

          <button
            type="button"
            className="btn btn-outline-danger"
            onClick={handleClear}
          >
            <i class="fa fa-times px-1"></i> Clear
          </button>
        </div>
        <div className="row clearfix">
          <div className="col-lg-12 col-lg-12 col-sm-12">
            <div className="card">
              <form className="card-body">
                <div className="form-group row">
                  <label className="col-lg-2 col-form-label">
                    Student Code <span className="text-danger">*</span>
                  </label>
                  <div className="col-lg-2">
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Student Code"
                        value={student_uid}
                        onChange={(e) => setstudent_uid(e.target.value)}
                      />
                      <div className="input-group-append">
                        <AddmittedStudentList
                          setstudent_uid={setstudent_uid}
                          setSelectedStudent={setSelectedStudent}
                        />
                      </div>
                      {validationError && !student_uid ? (
                        <ErrorDisplay errorMsg={"Student Code Requried !"} />
                      ) : null}
                    </div>
                  </div>
                  <label className="col-lg-2 col-form-label">
                    Student Name <span className="text-danger">*</span>
                  </label>
                  <div className="col-lg-2">
                    <input
                      type="text"
                      className="form-control"
                      value={std_name}
                      // onChange={(e) => setstdname(e.target.value)}
                      placeholder="Student Name"
                      disabled
                    />
                  </div>
                  <label className="col-lg-2 col-form-label">
                    Admission Date <span className="text-danger">*</span>
                  </label>
                  <div className="col-lg-2">
                    <input
                      type="date"
                      className="form-control"
                      value={admissionDate}
                      disabled
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-lg-2 col-form-label">
                    Class Section <span className="text-danger">*</span>
                  </label>
                  {/* {AdmissionData?.std_class + " " + AdmissionData?.section} */}
                  <div className="col-lg-2">
                    <Select
                      options={classListOption}
                      placeholder="Select Class"
                      value={classSelect}
                      onChange={handleclassselect}
                      isDisabled={true}
                    />
                    {validationError && !classSelect ? (
                      <ErrorDisplay errorMsg={"Select Class Section !"} />
                    ) : null}
                  </div>
                  <label className="col-lg-2 col-form-label">
                    Concession <span className="text-danger">*</span>
                  </label>
                  <div className="col-lg-2">
                    <Select
                      options={connessionList}
                      placeholder="Concession"
                      value={selectconnession}
                      onChange={handleSelectconnession}
                      isDisabled={true}
                    />
                    {validationError && !selectconnession ? (
                      <ErrorDisplay errorMsg={"Select Concession !"} />
                    ) : null}
                  </div>
                </div>{" "}
              </form>
            </div>

            <div className="d-flex justify-content-center align-items-center">
              <div className="table-responsive card col-lg-8 col-md-12">
                <table className="table table-hover table-vcenter table-striped mb-0 text-nowrap text-center">
                  <thead>
                    <tr>
                      <th className="text-center">Total</th>
                      <th className="text-center">Paid</th>
                      <th className="text-center">Balance</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{totalAmount}</td>
                      <td>{paidAmount}</td>
                      <td>{balanceAmount}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-header">
                    <h3 className="card-title">New Class</h3>
                  </div>
                  <div className="card-body">
                    <div className="form-group row">
                      <label className="col-lg-2 col-form-label">
                        Class Section <span className="text-danger">*</span>
                      </label>

                      <div className="col-lg-3">
                        <Select
                          options={classListOption?.filter(
                            (option) => option.value !== classSelect?.value
                          )} // Filter out the selected option
                          placeholder="Select Class"
                          value={newClass}
                          onChange={handleNewclassselect}
                          isDisabled={!student_uid}
                        />

                        {validationError && !classSelect ? (
                          <ErrorDisplay errorMsg={"Select Class Section !"} />
                        ) : null}
                      </div>
                      <label className="col-lg-2 col-form-label">
                        Concession <span className="text-danger">*</span>
                      </label>
                      <div className="col-lg-3">
                        <Select
                          options={connessionList}
                          placeholder="Concession"
                          value={newSelectconnession}
                          onChange={handleNewSelectconnession}
                          isDisabled={!student_uid}
                        />
                        {validationError && !selectconnession ? (
                          <ErrorDisplay errorMsg={"Select Concession !"} />
                        ) : null}
                      </div>
                    </div>{" "}
                    <div className="row clearfix">
                      <div className="table-responsive card">
                        <table className="table table-hover table-vcenter table-striped mb-0 text-nowrap">
                          <thead>
                            <tr>
                              <th></th>
                              <th>Sr.No.</th>
                              <th>Fee Heads</th>
                              <th className="text-center">Total Install</th>
                              <th className="text-center">Amount</th>
                              <th className="text-center">Discount</th>
                              <th className="text-center">Annual Fee Amount</th>
                            </tr>
                          </thead>
                          <tbody>
                            {tableData1?.map((row, index) => {
                              row.total_amount =
                                row.installments * row.installment_amount;
                              row.annual_fee =
                                row.installments * row.installment_amount;
                              return (
                                <tr key={index}>
                                  <td>
                                    <input
                                      type="checkbox"
                                      checked={row.checked}
                                      onChange={(e) =>
                                        handleCheckboxChange1(
                                          index,
                                          e.target.checked
                                        )
                                      }
                                    />
                                  </td>
                                  <td>{index + 1}</td>
                                  <td>{row.fees_head_name}</td>
                                  <td>
                                    <input
                                      disabled={!row.checked}
                                      type="number"
                                      className="form-control text-right"
                                      value={row.installments}
                                      onChange={(event) =>
                                        handleInputChange1(
                                          index,
                                          "installments",
                                          event.target.value
                                        )
                                      }
                                    />
                                  </td>
                                  <td>
                                    <input
                                      disabled={!row.checked}
                                      type="number"
                                      className="form-control text-right"
                                      value={row.installment_amount}
                                      onChange={(event) =>
                                        handleInputChange1(
                                          index,
                                          "installment_amount",
                                          event.target.value
                                        )
                                      }
                                    />
                                  </td>
                                  <td>
                                    <input
                                      disabled={!row.checked}
                                      type="text"
                                      className="form-control  text-right "
                                      value={row.discount}
                                      onChange={(event) => {
                                        if (
                                          /^-?\d*(\.\d*)?$/.test(
                                            event.target.value
                                          )
                                        ) {
                                          const numericValue = Number(
                                            event.target.value
                                          );
                                          if (numericValue <= row.annual_fee) {
                                            if (numericValue < 0) {
                                              alert.info(
                                                "Amount can not be less than zero"
                                              );
                                            } else {
                                              handleInputChange1(
                                                index,
                                                "discount",
                                                numericValue.toString()
                                              );
                                            }
                                          } else {
                                            alert.info(
                                              "Discount can not be greater than Annual Fee Amount"
                                            );
                                          }
                                        }
                                      }}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      disabled
                                      type="number"
                                      className="form-control text-right"
                                      value={row.annual_fee}
                                      onChange={(event) => {
                                        handleInputChange1(
                                          index,
                                          "annual_fee",
                                          event.target.value
                                        );
                                      }}
                                    />
                                  </td>
                                  <span className="d-none">
                                    {total_amount.push(row.annual_fee)}
                                  </span>
                                </tr>
                              );
                            })}
                            <tr className="">
                              <th colSpan={6} className="text-right text-dark">
                                <h1 className="page-title">Total Amount</h1>
                              </th>

                              <th colSpan={1} className="text-right text-dark">
                                <h1 className="page-title">
                                  {Sum_total_amount()}
                                </h1>
                              </th>
                            </tr>
                          </tbody>
                        </table>
                      </div>

                      <div className="col-12 mb-3">
                        <label className="col-lg-2 col-form-label">
                          Remark <span className="text-danger">*</span>
                        </label>
                        <div className="col-lg-12">
                          <input
                            type="text"
                            className="form-control"
                            value={remark}
                            onChange={(e) => setRemark(e.target.value)}
                            placeholder="Enter your remark"
                          />
                        </div>
                      </div>

                      <div className="col-12 d-flex justify-content-end">
                        <button
                          type="button"
                          className="btn btn-primary btn-lg"
                          onClick={HandelSectionChange}
                          disabled={
                            !newClass?.value ||
                            !newSelectconnession?.value ||
                            !feeStructure ||
                            !remark?.trim() // This ensures blank spaces are treated as empty
                          }
                        >
                          <i className="fa fa-times px-1"></i> Save
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangeSection;
