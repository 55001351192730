import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import Swal from "sweetalert2";
import { useAlert } from "react-alert";
import { updateFeeDiscount } from "../../../actions/school_user/FeeDiscount";
import { getStudentById } from "../../../actions/school_user/Certificate";
import { feeDiscountAPI } from "../../../api/Admission/feeDiscountAPI";
import { getMasterData } from "../../../actions/super-user/MasterData";
import useSessionRedirect from "../Comman/SessionChange";
import StudentListSessionWize2 from "../Comman/StudentListSessionWize2";
import StudentList2 from "../Comman/StudentList2";
import Loader from "../../Loader/Loader";
const FeeDiscount = () => {
  const token = localStorage.getItem("token");
  const school_user = JSON.parse(localStorage.getItem("school_user"));
  const [FeesTable, setFeesTable] = useState([]);
  const { masterData } = useSelector((state) => state.masterData);
  const [student_uid, setstudent_uid] = useState("");
  const { id, user_type, name, user_detail } = school_user;
  let { school_emp_id, fk_school_id, first_name, last_name } =
    user_detail || {};
  const session_id = useSessionRedirect();
  const { regularFees } = useSelector((state) => state.getRegularFees);
  const { studata } = useSelector((state) => state.certificate);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const alert = useAlert();
  const [discount_authority, setdiscount_authority] = useState("");
  const [isRemark, setIsRemark] = useState(`${first_name} ${last_name}` || "");
  const [getDiscountReason, setGetDiscountReason] = useState([]);
  const [selectDiscountReason, setSelectDiscountReason] = useState(null);
  const [stu_adm_id, setStu_adm_id] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [disabledAll, setDisabledAll] = useState(false);

  const [remValue, setRemValue] = useState();

  let idS = studata?.payload?.id;

  let studentAdmId = studata?.payload?.stu_adm_id;
  let nameS = studata?.payload?.name;
  let classSection = `${studata?.payload?.std_class} -${studata?.payload?.section}`;
  let statusS = studata?.payload?.student_status;
  if (student_uid === "") {
    nameS = "";
    idS = "";
    statusS = "";
    studentAdmId = "";
    classSection = "";
  } else if (student_uid !== studata?.payload?.student_code) {
    nameS = "";
    idS = "";
    statusS = "";
    studentAdmId = "";
    classSection = "";
  }
  // useEffect(() => {
  //   setSelectDiscountReason(
  //     studata?.payload?.admission_data?.fk_discount_reason_id
  //   );
  //   setdiscount_authority(studata?.payload?.admission_data?.discount_authority);
  //   setIsRemark(studata?.payload?.admission_data?.remark);
  // }, [studata, nameS]);

  useEffect(() => {
    const fetchData = async () => {
      if (student_uid) {
        const body = {
          fk_academic: session_id,
          student_code: student_uid,
          school_id: Number(user_detail.fk_school_id),
        };
        try {
          let response = await dispatch(getStudentById(body));
          if (response.status === 500) {
            Swal.fire({
              text: response.msg,
              icon: "warning",
              imageAlt: "warning image",
            }).then((result) => {
              if (result.isConfirmed) {
                setstudent_uid("");
              }
            });
          }
        } catch (error) {
          console.error("Error fetching student data:", error);
        }
      }
    };
    fetchData();
  }, [student_uid, session_id, dispatch]);

  const FeesDetails = async () => {
    setFeesTable([]);
    setIsLoading(true);
    try {
      let body = {
        fk_academic: session_id,
        student_id: idS,
        school_id: Number(user_detail.fk_school_id),
        call_from: "",
      };
      const response = await feeDiscountAPI.getRegularFees(body);
      const newResponse = response?.data?.payload?.map((item) => ({
        ...item,
        dis_count: item.discount,
        discount: 0,
      }));
      setFeesTable(newResponse);
    } catch (error) {
      console.error(error);
      setFeesTable([]);
    } finally {
      setIsLoading(false);
    }
  };

  const FeesDetails2 = async (id) => {
    setTimeout(() => setDisabledAll(true), 0);
    setFeesTable([]);
    setIsLoading(true);
    try {
      let body = {
        fk_academic: session_id,
        student_id: id,
        school_id: Number(user_detail.fk_school_id),
        call_from: "",
      };
      const response = await feeDiscountAPI.getRegularFees(body);
      const newResponse = response?.data?.payload?.map((item) => ({
        ...item,
        dis_count: item.discount,
        discount: 0,
      }));
      let responseId = response?.data?.student_adm_id;
      getFeeDiscountDetails(responseId, newResponse);
    } catch (error) {
      console.error(error);
      setFeesTable([]);
    } finally {
      setIsLoading(false);
    }
  };

  const getFeeDiscountDetails = async (responseId, data) => {
    setdiscount_authority("");
    setIsRemark(`${first_name} ${last_name}` || "");
    setSelectDiscountReason(null);
    setIsLoading(true);
    try {
      let body = {
        stu_adm_id: responseId,
      };
      const response = await feeDiscountAPI.getStudentDiscountApi(body);

      const newVal = response.data.is_discount;
      if (newVal === "False" ? true : false) {
        console.log("Discount Avalable");
        Swal.fire({
          text: "No Discount avalable for this student.",
          icon: "warning",
          title: "ALERT",
          imageAlt: "warning image",
        });

        handleClear();
        return;
      }
      const value = response?.data.discount_report[0];

      setRemValue(value);
      setdiscount_authority(value?.discount_authority);
      // let reson = value?.discount_resason
      // Assuming "bad debt" is the discount reason you want to match
      const discountReason = value?.discount_resason;

      // Find the matching discount reason in the discountReasonList
      const matchedReason = discountReasonList.find(
        (reason) => reason.label.toLowerCase() === discountReason.toLowerCase()
      );

      // If a match is found, save the value in the state
      if (matchedReason) {
        setTimeout(() => {
          setSelectDiscountReason(matchedReason.value);
        }, 0); // Assuming you're using setDiscountReasonValue to update state
      } else {
        console.log("No match found for discount reason:", discountReason);
      }

      setFeesTable(data);
    } catch (error) {
      console.error(error);
      handleClear();
    } finally {
      setIsLoading(false);
    }
  };

  const handleClear = () => {
    setdiscount_authority("");
    setIsRemark(`${first_name} ${last_name}` || "");
    setstudent_uid("");
    setSelectDiscountReason(null);
    setDisabledAll(false);
  };

  const handleClear2 = () => {
    setdiscount_authority("");
    setIsRemark(`${first_name} ${last_name}` || "");
    setSelectDiscountReason(null);
    setDisabledAll(false);
  };

  useEffect(() => {
    if (idS) {
      FeesDetails();
    } else {
      setFeesTable([]);
    }
  }, [student_uid, idS, session_id]);

  const handleInputChange1 = (index, field, value) => {
    const newData = [...FeesTable];
    newData[index][field] = value;
    setFeesTable(newData);
  };

  const makeAdmission = async () => {
    if (!discount_authority || !isRemark || !selectDiscountReason) {
      Swal.fire({
        title: "",
        text: "Please Enter Required Fields",
        icon: "error",
        imageAlt: "error image",
      });
      return;
    }
    const fee_discount_list = FeesTable?.map(
      ({ annual_fee, discount, id, feeshead_name }) => {
        return {
          annual_fee: Number(annual_fee),
          discount: Number(discount),
          heads_name: feeshead_name,
          id,
        };
      }
    );
    setIsLoading(true);
    try {
      const body = {
        student_admission_id: studata?.payload?.admission_data?.id,
        fee_discount_list,
        remark: isRemark,
        discount_authority,
        insert_by: school_emp_id,
        fk_discount_reason_id: selectDiscountReason,
      };

      let response = await dispatch(updateFeeDiscount(body));
      if (response.status === 200) {
        Swal.fire({
          text: response.msg,
          icon: "success",
          imageAlt: "success image",
        }).then((result) => {
          if (result.isConfirmed) {
            handleClear();
          }
        });
      }
      if (response.status === 403) {
        Swal.fire({
          text: response.msg,
          icon: "error",
          imageAlt: "error image",
        });
      }
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  const discountReasonList = getDiscountReason?.map((cl) => {
    return {
      label: cl.value,
      value: cl.id,
    };
  });

  useEffect(() => {
    setGetDiscountReason(
      masterData?.payload?.filter((obj) => obj.name === "DiscountReason")
    );
  }, [masterData, session_id]);
  useEffect(() => {
    if (token) {
      dispatch(getMasterData(token));
    }
  }, [dispatch, token, session_id]);

  const CancelReceipt = async (row) => {
    let body = {
      stu_adm_id: studentAdmId,
      discount_id: row.id,
      heads_name: row.feeshead_name,
    };
    Swal.fire({
      title: "CONFIRMATION",
      text: "Do you want to Cancel Receipt?",
      icon: "question",
      showDenyButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#f46a6a",
      confirmButtonText: "Yes",
      denyButtonText: "No",
      allowOutsideClick: false,
    }).then(async (result) => {
      if (result.isConfirmed) {
        const resposne = await feeDiscountAPI.deleteDiscountApi(body);
        let resposneData = resposne.data;
        if (resposneData.status === 200) {
          Swal.fire({
            text: resposneData.msg,
            icon: "success",
          }).then((result) => {
            if (result.isConfirmed) {
              FeesDetails2();
            }
          });
        }
        if (resposneData.status === 403) {
          Swal.fire({
            text: resposneData.msg,
            icon: "error",
            imageAlt: "error image",
          });
        }
      }
    });
  };
  const total_amount = [];
  const Sum_total_amount = () => {
    const sum = total_amount.reduce((acc, current) => acc + current, 0);
    return sum;
  };
  if(isLoading) return <Loader />
  return (
    <>
      <div className="section-body">
        <div className="container-fluid">
          <div className="d-flex justify-content-between align-items-center">
            <div className="header-action">
              <h1 className="page-title">Fees Discount</h1>
              {/* <ol className="breadcrumb page-breadcrumb">
                <li className="breadcrumb-item active" aria-current="page">
                  Regular Fees Receipt
                </li>
              </ol> */}
            </div>
            <ul className="nav nav-tabs page-header-tab">
              <li className="nav-item">
                <StudentList2
                  FeesDetails={FeesDetails2}
                  setstudent_uid={setstudent_uid}
                  setDisabledAll={setDisabledAll}
                  setStu_adm_id={setStu_adm_id}
                  student_uid={student_uid}
                />
              </li>
            </ul>
          </div>
        </div>
        <br />
        <div className="container-fluid">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <div className="row">
                    <div className="col-lg-3 col-md-6 col-sm-6">
                      <label className="col-form-label mx-2">
                        Student Code <span className="text-danger">*</span>
                      </label>
                      <div className="input-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Student Code"
                          value={student_uid}
                          onChange={(e) => setstudent_uid(e.target.value)}
                        />
                        <StudentListSessionWize2
                          setstudent_uid={setstudent_uid}
                          setDisabledAll={setDisabledAll}
                          setSelectDiscountReason={setSelectDiscountReason}
                          handleClear2={handleClear2}
                          // isDiscount = {true}
                        />
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6">
                      <label className="col-form-label mx-2">
                        Student Name <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Student Name"
                        disabled
                        value={nameS}
                      />
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6">
                      <label className="col-form-label mx-2">
                        Class Section <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Student Name"
                        disabled
                        value={classSection}
                      />
                    </div>
                    {/* <div className="col-lg-4 col-md-6 col-sm-6 justify-self-end">
                      <button type="button" className="btn btn-outline-danger mx-2" onClick={handleClear}><i class="fa fa-times px-1"></i>Clear</button>
                      <button type="button" class="btn btn-danger mx-1" onClick={()=>CancelReceipt()}>Cancel</button>
                    </div> */}
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12"></div>
              </div>
            </div>
          </div>

          <div className="table-responsive card">
            <table className="table table-hover table-vcenter table-striped mb-0 text-nowrap">
              <thead>
                <tr className="text-center">
                  <th>Sr.No.</th>
                  <th>Fees Heads</th>
                  <th>Fees Amount</th>
                  <th>Balance</th>
                  <th>Discount</th>
                  {disabledAll && <th>action</th>}
                </tr>
              </thead>
              <tbody>
                {nameS &&
                  FeesTable?.map((row, index) => {
                    // row.annual_fee =
                    //   row.installments * row.installment_amount - row.dis_count;
                    // row.due_amount = row.annual_fee - row.paid_amount;

                    return (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{row.feeshead_name}</td>
                        <td>
                          <input
                            type="number"
                            className="form-control text-right"
                            value={row.annual_fee}
                            onChange={(event) => {
                              handleInputChange1(
                                index,
                                "annual_fee",
                                event.target.value
                              );
                            }}
                            disabled
                          />
                        </td>
                        <td>
                          <input
                            type="number"
                            className="form-control text-right"
                            value={row.due_amount}
                            onChange={(event) =>
                              handleInputChange1(
                                index,
                                "due_amount",
                                event.target.value
                              )
                            }
                            disabled
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className="form-control text-right"
                            value={disabledAll ? row.dis_count : row.discount}
                            disabled={disabledAll}
                            onChange={(event) => {
                              if (/^-?\d*(\.\d*)?$/.test(event.target.value)) {
                                const numericValue = Number(event.target.value);
                                if (numericValue <= row.due_amount) {
                                  if (numericValue < 0) {
                                    alert.info(
                                      "Amount can not be less than zero"
                                    );
                                  } else {
                                    handleInputChange1(
                                      index,
                                      "discount",
                                      numericValue.toString()
                                    );
                                  }
                                } else {
                                  alert.info(
                                    "Discount can not be greater than Fee Amount"
                                  );
                                }
                              }
                            }}
                          />
                        </td>
                        {disabledAll ? (
                          <td>
                            <button
                              type="button"
                              className="btn btn-danger mx-1"
                              onClick={() => CancelReceipt(row)}
                              disabled={row.dis_count > 0 ? false : true}
                            >
                              Cancel
                            </button>
                          </td>
                        ) : null}

                        <span className="d-none">
                          {total_amount.push(
                            Number(disabledAll ? row.dis_count : row.discount)
                          )}
                        </span>
                      </tr>
                    );
                  })}

                <tr className="">
                  <th colSpan={4} className="text-right text-dark">
                    <h1 className="page-title mr-0">TOTAL DISCOUNT</h1>
                  </th>

                  <th colSpan={1} className="text-right text-dark">
                    <h1 className="page-title mr-0">{Sum_total_amount()}</h1>
                  </th>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="col-lg-6">
            <div className="row mt-2">
              <div className="col-6 ">
                <h6 className="m-1">
                  Discount Reason <span className="text-danger">*</span>
                </h6>
              </div>
              <div className="col-6">
                <Select
                  options={discountReasonList}
                  placeholder="Discount Reason"
                  value={
                    nameS && selectDiscountReason
                      ? discountReasonList?.find(
                          (s) => s.value === selectDiscountReason
                        )
                      : null
                  }
                  onChange={(selectedOption) =>
                    setSelectDiscountReason(selectedOption?.value)
                  }
                  isDisabled={disabledAll}
                />
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-6 ">
                <h6 className="m-1">
                  Discount Authority <span className="text-danger">*</span>
                </h6>
              </div>
              <div className="col-6">
                <input
                  className="form-control"
                  value={nameS && discount_authority}
                  placeholder="Discount Authority"
                  onChange={(e) => setdiscount_authority(e.target.value)}
                  disabled={disabledAll}
                />
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-6">
                <h6 className="m-1">
                  Discount Given by <span className="text-danger">*</span>
                </h6>
              </div>
              <div className="col-6">
                <input
                  className="form-control"
                  value={disabledAll ? remValue?.remark : isRemark}
                  placeholder="Discount Given by"
                  // onChange={(e) => setIsRemark(e.target.value)}
                  readOnly
                />
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-end align-item-center my-3">
            <button
              disabled={(!nameS && true) || disabledAll}
              type="button"
              className="mr-1 btn btn-primary btn-sm px-4"
              onClick={() => makeAdmission()}
            >
              <i className="fa fa-plus px-1"></i> Save
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default FeeDiscount;

// {
//   "student_admission_id": 3427,
//   "fee_discount_list": [
//       {
//           "annual_fee": 10000,
//           "discount": 10,
//           "id": 7720
//       },
//       {
//           "annual_fee": 50000,
//           "discount": 10,
//           "id": 7721
//       },
//       {
//           "annual_fee": 25000,
//           "discount": 10,
//           "id": 7722
//       },
//       {
//           "annual_fee": 25001,
//           "discount": 10,
//           "id": 7723
//       },
//       {
//           "annual_fee": 17500,
//           "discount": 10,
//           "id": 160
//       },
//       {
//           "annual_fee": 16000,
//           "discount": 10,
//           "id": 677
//       }
//   ],
//   "remark": "Shivam Khandar",
//   "discount_authority": "ccsasc",
//   "insert_by": 607,
//   "fk_discount_reason_id": 207
// }
